import * as React from 'react';
import { Box } from '@mui/system';
import { Dialog, Tab, Button, Stack, Select, MenuItem, Alert } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import axios from 'axios';
import ShutterStock from './ShutterStock';
import Config from '../config.json';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TuneIcon from '@mui/icons-material/Tune';
import Pexels from './Pexels';
import Pixabay from './Pixabay';
import Unsplash from './Unsplash';
import ImagesAI from './ImagesAI';

export default function ImagesApi({open, handleClose, post_title, editorRef, setImagePreview, setInputs, idArticle }) {
  const [value, setValue] = React.useState('pexels');
  const [url, setUrl] = React.useState('/api/v2/images/pexels');
  const [images, setImages] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [mode, setMode] = React.useState('get');
  const [enableFilter, setEnableFilter] = React.useState(false);
  const [orientation, setOrientation] = React.useState('');
  const [displayError, setDisplayError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [date, setDate] = React.useState(new Date());
  const [page, setPage] = React.useState(1);

  const[openLoader, setOpenLoader] = React.useState(false);


  const handleChange = (event, newValue) => {
    setImages([]);
    setSearch('')
    setPage(1);
    setValue(newValue);
    setMode('get');
    setOrientation('')
    setDisplayError(false);
    setEnableFilter(false);
    switch (newValue) {
        case 'pexels':
            setUrl('/api/v2/images/pexels');
            break;
        case 'shutterstock':
            setUrl('/api/v2/images/shutterstock');
            break;
        case 'unsplash':
            setUrl('/api/v2/images/unsplash');
            break;
        case 'pixabay':
            setUrl('/api/v2/images/pixabay');
            break;
        case 'imagesai':
            setUrl('/api/v2/images/imagesai');
            break;
        default:
            break;
    }
  };

  React.useEffect(() => {

    if(open){

        

        if( mode === 'get' ){

            if( url.includes("/api/v2/images/pexels") ){ // PEXELS
                axios.get(Config.BASE_URL + url + '?page=' + page + '&orientation=' + orientation, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                })
                .then((response) => {
                    if(response.data.photos.length > 0){
                        setImages(prevImages => [...prevImages, ...response.data.photos]); 
                        setDisplayError(false)

                    }
                    else{
                        setDisplayError(true)
                        setErrorMsg('Erreur dans la récupération des images ! (Code erreur : APIPEX1)')
                    }
                })
                .catch((error) => {
                    setDisplayError(true)
                    setErrorMsg('Erreur dans la récupération des images ! (Code erreur : APIPEX2)')
                })
            }else if( url.includes('/api/v2/images/shutterstock')){ // SHUTTERSTOCK
                axios.get(Config.BASE_URL + url + '?text=' + encodeURI(post_title) + '&page=' + page + '&orientation=' + orientation, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                })
                .then((response) => {
                    if(response.data.length > 0){
                        setImages(prevImages => [...prevImages, ...response.data]); 
                        setDisplayError(false)

                    }else{
                        setDisplayError(true)
                        setErrorMsg('Erreur dans la récupération des images ! (Code erreur : APISHU1)')
                    }
                })
                .catch((error) => {
                    setDisplayError(true)
                    setErrorMsg('Erreur dans la récupération des images ! (Code erreur : APISHU2)')
                })
            }else if( url.includes('/api/v2/images/unsplash')){ // UNSPLASH

                axios.get(Config.BASE_URL + url + '?page=' + page + '&orientation=' + orientation, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    }
                })
                .then((response) => {
                    if(response.data.length > 0){
                        setImages(prevImages => [...prevImages, ...response.data]); 
                        setDisplayError(false)

                    }else{
                        setDisplayError(true)
                        setErrorMsg('Erreur dans la récupération des images ! (Code erreur : APIUNS1)')
                    }
                })
                .catch((error) => {
                    setDisplayError(true)
                    setErrorMsg('Erreur dans la récupération des images ! (Code erreur : APIUNS2)')
                })

            }else if( url.includes('/api/v2/images/pixabay')){ // PIXABAY

                axios.get(Config.BASE_URL + url + '?page=' + page + '&orientation=' + orientation, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    }
                })
                .then((response) => {
                    if(response.data.hits.length > 0){
                        setImages(prevImages => [...prevImages, ...response.data.hits]);
                        setDisplayError(false)

                    }else{
                        setDisplayError(true)
                        setErrorMsg('Erreur dans la récupération des images ! (Code erreur : APIPIX1)')
                    }
                })
                .catch((error) => {
                    setDisplayError(true)
                    setErrorMsg('Erreur dans la récupération des images ! (Code erreur : APIPIX2)')
                })
            }

        }else if (mode === 'search'){

            if(value === 'shutterstock'){ // SHUTTERSTOCK
                axios.get(Config.BASE_URL + '/api/v2/images/shutterstock?search=' + encodeURI(search) + '&page=' + page + '&orientation=' + orientation, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                })
                .then((response) => {
                    if(response.data.length > 0){
                        setImages(prevImages => [...prevImages, ...response.data]);
                        setDisplayError(false)

                    }else{
                        setDisplayError(true)
                        setErrorMsg('Erreur dans la récupération des images ! (Code erreur : APISHU3)')
                    }
                })
                .catch((error) => {
                    setDisplayError(true)
                    setErrorMsg('Erreur dans la récupération des images ! (Code erreur : APISHU4)')
                })
            }else if( value === "pexels" ){ // PEXELS
                axios.get(Config.BASE_URL + '/api/v2/images/pexels?search=' + encodeURI(search) + '&page=' + page + '&orientation=' + orientation, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                })
                .then((response) => {
                    if(response.data.photos.length > 0){
                        setImages(prevImages => [...prevImages, ...response.data.photos]); 
                    }else{
                        setDisplayError(true)
                        setErrorMsg('Erreur dans la récupération des images ! (Code erreur : APIPEX3)')
                    }
                })
                .catch((error) => {
                    setDisplayError(true)
                    setErrorMsg('Erreur dans la récupération des images ! (Code erreur : APIPEX4)')
                })
            }else if ( value === "unsplash" ){ // UNSPLASH

                axios.get(Config.BASE_URL + '/api/v2/images/unsplash?search=' + encodeURI(search) + '&page=' + page + '&orientation=' + orientation, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                })
                .then((response) => {
                    if(response.data.length > 0){
                        setImages(prevImages => [...prevImages, ...response.data]); 
                        setDisplayError(false)

                    }else{
                        setDisplayError(true)
                        setErrorMsg('Erreur dans la récupération des images ! (Code erreur : APIUNS3)')
                    } 

                })
                .catch((error) => {
                    setDisplayError(true)
                    setErrorMsg('Erreur dans la récupération des images ! (Code erreur : APIUNS4)')
                })
            }else if( value === "pixabay" ){ // PIXABAY

                axios.get(Config.BASE_URL + '/api/v2/images/pixabay?search=' + encodeURI(search) + '&page=' + page + '&orientation=' + orientation, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                })
                .then((response) => {
                    if(response.data.hits.length > 0){
                        setImages(prevImages => [...prevImages, ...response.data.hits]); 
                        setDisplayError(false)

                    }else{
                        setDisplayError(true)
                        setErrorMsg('Erreur dans la récupération des images ! (Code erreur : APIPIX3)')
                    }
                })
                .catch((error) => {
                    setDisplayError(true)
                    setErrorMsg('Erreur dans la récupération des images ! (Code erreur : APIPIX4)')
                })
            }else if( value === "imagesai" ){ // IMAGESAI

                axios.get(Config.BASE_URL + '/api/v2/images/imagesai?search=' + encodeURI(search) + '&page=' + page, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                })
                .then((response) => {
                    if(response.data.images.length > 0){
                        setImages(prevImages => [...prevImages, ...response.data.images]); 
                        setDisplayError(false)
                    }else{
                        setDisplayError(true)
                        setErrorMsg('Erreur dans la récupération des images ! (Code erreur : APIIMA3)')
                    }
                })

            }

        }
  
  
    }
  
  }, [open, value, page, mode, orientation, date])
  

  const handleSearch = () => {
        setMode('get')
        setMode('search');
        setPage(1);
        setImages([]);
        setDate(new Date());
  }

  const handleNextPage = () => {
    setPage(page + 1);
  }

  return (
    <Dialog fullWidth onClose={() => {setImages([]); handleClose(false); setPage(1);}}  open={open} maxWidth="lg">
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange}>
                    <Tab label="ShutterStock" value="shutterstock" />
                    <Tab label="Pexels" value="pexels" />
                    <Tab label="Unsplash" value="unsplash" />
                    <Tab label="Pixabay" value="pixabay" />
                    <Tab label="Images AI" value="imagesai" />
                </TabList>
                </Box>
                {displayError && (

                    <Alert severity="warning">{errorMsg}</Alert>

                )}
                { !displayError && (

                    
                    <>
                        { value !== 'imagesai' ? (
                            <Alert severity="info" sx={{alignItems: 'center'}}>Lors de l'utilisation des banques d'images, des filtres seront automatiquement appliqués afin de créer une image unique. (couleur, retournement horizontal, cropping ou bien encore redimensionnement)</Alert>
                        )
                         :
                        (
                            <Alert severity="info" sx={{alignItems: 'center'}}>Vous devez rechercher avec un mot clé en anglais !</Alert>
                        )
                        }
                    </>

                )}
                <Stack sx={{flexDirection: 'row', alignItems: 'center'}}>

                    <FormControl sx={{ m: 1, width: '70ch', margin: 'auto', marginTop: '20px' }} variant="outlined">
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={openLoader}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <InputLabel htmlFor="outlined-adornment-search">Recherche...</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-search"
                            type={'text'}
                            endAdornment={
                            <InputAdornment position="end">
                                {search !== '' && (
                                    <IconButton
                                    onClick={() => setSearch('')}
                                    edge="end"
                                    sx={{mr: 1}}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                )}
                                
                                <IconButton
                                onClick={handleSearch}
                                edge="end"
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                            }
                            label="Recherche..."
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                        />
                    </FormControl>

                    { ((value !== 'pexels' && value !== 'unsplash') || mode !== 'get' || value === 'shutterstock') && (
                        <IconButton 
                            aria-label="filtre" 
                            sx={{marginRight: '20px', marginTop: '20px'}} 
                            onClick={ () => setEnableFilter(!enableFilter)}
                        >
                            <TuneIcon />
                        </IconButton>
                    )}



                </Stack> 

                {enableFilter && (


                    <Stack sx={{flexDirection: 'row', margin: 'auto', marginTop: '20px'}} pr={2} pl={2}>

                    { (value === 'shutterstock' || value === 'pixabay' ) &&  (

                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel>Orientation</InputLabel>
                            <Select
                            value={orientation}
                            label="Orientation"
                            onChange={(e) => {setOrientation(e.target.value); setImages([])}}
                            >
                                <MenuItem value="">Aucun</MenuItem>
                                <MenuItem value="vertical">Portrait</MenuItem>
                                <MenuItem value="horizontal">Paysage</MenuItem>
                            </Select>
                        </FormControl>
                    )}

                    { (value === 'pexels' || value === 'unsplash') && (
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel>Orientation</InputLabel>
                            <Select
                            value={orientation}
                            label="Orientation"
                            onChange={(e) => {setOrientation(e.target.value); setImages([])}}
                            >
                                <MenuItem value="">Aucun</MenuItem>
                                <MenuItem value="portrait">Portrait</MenuItem>
                                <MenuItem value="landscape">Paysage</MenuItem>
                                <MenuItem value="square">Carré</MenuItem>
                            </Select>
                        </FormControl>
                    )}


                    </Stack>
                    
                )}


                <TabPanel value="shutterstock">
                    { value === 'shutterstock' && (
                        <ShutterStock idArticle={idArticle} images={images} editorRef={editorRef} handleClose={handleClose} setSearch={setSearch} setImagePreview={setImagePreview} setImages={setImages} setInputs={setInputs} setOpenLoader={setOpenLoader}/>
                    )}
                </TabPanel>
                <TabPanel value="pexels">
                    {
                        value === 'pexels' && (
                            
                            <Pexels idArticle={idArticle} images={images} editorRef={editorRef} handleClose={handleClose} setSearch={setSearch} setImagePreview={setImagePreview} setImages={setImages} setInputs={setInputs} setOpenLoader={setOpenLoader}/>

                        )
                        
                    }
                </TabPanel>
                <TabPanel value="unsplash">
                    { value === 'unsplash' && (
                        <Unsplash idArticle={idArticle} images={images} editorRef={editorRef} handleClose={handleClose} setSearch={setSearch} setImagePreview={setImagePreview} setImages={setImages} setInputs={setInputs} setOpenLoader={setOpenLoader}/>
                    )}
                </TabPanel>

                <TabPanel value="pixabay">
                    { value === 'pixabay' && (
                        <Pixabay idArticle={idArticle} images={images} editorRef={editorRef} handleClose={handleClose} setSearch={setSearch} setImagePreview={setImagePreview} setImages={setImages} setInputs={setInputs} setOpenLoader={setOpenLoader}/>
                    )}
                </TabPanel>

                <TabPanel value="imagesai">
                    { value === 'imagesai' && (
                        <ImagesAI idArticle={idArticle} images={images} editorRef={editorRef} handleClose={handleClose} setSearch={setSearch} setImagePreview={setImagePreview} setImages={setImages} setInputs={setInputs} setOpenLoader={setOpenLoader}/>
                    )}
                </TabPanel>

                <Button onClick={handleNextPage}>Voir plus</Button>

            </TabContext>
    </Dialog>
    );
}