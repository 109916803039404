import * as React from 'react';
import Chip from '@mui/material/Chip';
import { createTheme, ThemeProvider } from '@mui/material/styles';



export default function CustomChip(props) {

    const theme = createTheme({
        palette: {
          primary: {
            main: '#3C035D',
          },
          secondary: {
            main: '#FCA200',
          },
          info: {
            main: '#D60088',
          },
          default: {
            main: '#58C66A',
          },
          validate: {
            light: '#58C66A',
            main: '#58C66A',
            dark: '#58C66A',
            contrastText: 'rgba(0, 0, 0, 0.87)',
          },
        },
    });

    var color = ''

    switch(props.label){
        case 'À rédiger':
            color = "primary"
            break
        case 'En rédaction':
            color= "secondary"
            break;
        case 'Contrôle qualité':
            color= "info"
            break;
        case 'Validé':
            color = "validate";
            break;
        case 'Refusé':
            color = "error";
            break;
        default:
            color = "primary"
            break
    }

  return (
        <ThemeProvider theme={theme}>
            <Chip label={props.label} color={color} />
        </ThemeProvider>
    );
}