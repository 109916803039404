import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import Config from '../config.json';
import {Button} from '@mui/material';
import {useState} from 'react';
import Chip from '@mui/material/Chip';
import {Snackbar, Grid} from '@mui/material';

import CustomLogo from '../components/CustomSvg';
import Layout from '../components/Layout';

function CorrectArticleContent() {
  const [api, setApi] = React.useState('');
  const [openMsg, setOpenMsg] = useState(false);

  const download = (e) => {
    const link = document.createElement('a');
    link.download = `sn-plugin.zip`;
    link.href = Config.BASE_URL + '/sn-plugin.zip';
    link.click();
  };

  const handleClick = () => {
    setOpenMsg(true);

    var copyText = document.getElementById('api');
    copyText = copyText.getElementsByClassName('MuiChip-label');
    navigator.clipboard.writeText(copyText[0].innerHTML);
  };

  React.useEffect(() => {
    const getApiKey = async () => {
      const apiKeyFromApi = await axios.get(
        Config.BASE_URL + '/api/v2/apikey?role=gc',
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      // console.log(apiKeyFromApi.data)
      setApi(apiKeyFromApi.data);
    };

    getApiKey();
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h5">
            N'oubliez pas d'utiliser notre plugin wordpress
          </Typography>
          <Button
            variant="outlined"
            startIcon={<CustomLogo />}
            onClick={download}
            sx={{ml: 10}}
          >
            Télécharger
          </Button>
        </Paper>

        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            mt: 5,
          }}
        >
          <Typography variant="h6">
            Pour l'utiliser, vous aurez besoin d'une clé API :
          </Typography>
          <Chip
            id="api"
            label={api}
            variant="outlined"
            onClick={handleClick}
            sx={{ml: 10}}
          />
        </Paper>
        <Snackbar
          message="Copié"
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          autoHideDuration={2000}
          onClose={() => setOpenMsg(false)}
          open={openMsg}
        />
      </Grid>
    </>
  );
}

export default function CorrectArticle() {
  return <Layout children={<CorrectArticleContent />} />;
}
