import * as React from 'react';
import {Link as LinkRouter} from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DeployList from './DeployList';
import PeopleIcon from '@mui/icons-material/People';
import {useOutletContext} from 'react-router-dom';
import ApiIcon from '@mui/icons-material/Api';
import FacturationDeployList from './FacturationDeployList';

export default function MainListItems() {
  const linkStyle = {
    textDecoration: 'none',
    color: 'rgba(0,0,0,0.87)',
  };
    const loggedUser = useOutletContext();

    var display =
      (loggedUser[0].id_role == 1 ||
      loggedUser[0].id_role == 2)

    var display_gc = loggedUser[0].id_role == 5

  return (
    <React.Fragment>
      <LinkRouter to="/dashboard" style={linkStyle}>
        <ListItemButton>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
      </LinkRouter>

      <DeployList display={display} display_gc={display_gc} />

      {display && (
        <LinkRouter to="/users" style={linkStyle}>
          <ListItemButton>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Utilisateurs" />
          </ListItemButton>
        </LinkRouter>
      )}

      {display_gc  && (
        <LinkRouter to="/api" style={linkStyle}>
          <ListItemButton>
            <ListItemIcon>
              <ApiIcon />
            </ListItemIcon>
            <ListItemText primary="API" />
          </ListItemButton>
        </LinkRouter>
      )}

      { (loggedUser[0].id_role != 5 && loggedUser[0].premium == 'false') && (

        <FacturationDeployList display={display} premium={loggedUser[0].premium} />

      )}

    </React.Fragment>
  );
}
