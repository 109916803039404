import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {useOutletContext, useParams} from 'react-router-dom';
import axios from 'axios';
import Config from '../config.json';
import {
  Stack,
  TextField,
  FormControl,
  MenuItem,
  Button,
  InputLabel,
  Select,
  Fab,
  Snackbar,
  Collapse,
} from '@mui/material';
import ImageUploading from 'react-images-uploading';
import Editor from '../components/WYSIWYG/Editor';
import {useState} from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import AddTaskIcon from '@mui/icons-material/AddTask';
import BlockIcon from '@mui/icons-material/Block';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Layout from '../components/Layout';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import ImagesApi from '../components/ImagesApi';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ViewArticleContent() {
  const editorRef = React.useRef(null);
  var [inputs, setInputs] = useState([]);
  const {id_article} = useParams();
  const loggedUser = useOutletContext();
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [content, setContent] = useState('');
  const [nameRedac, setNameRedac] = useState([]);
  const [redac, setRedac] = useState('');
  const [imagePreview, setImagePreview] = useState('');
  const [openImagesApi, setOpenImagesApi] = useState(false);
  const [openDial, setOpenDial] = React.useState(false);
  const handleOpen = () => setOpenDial(true);
  const handleClose = () => setOpenDial(false);

  const [openDialog, setOpenDialog] = useState(false);
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setActionReattribute('');
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSuccess}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [mailto, setMailto] = useState('');
  const [displayReattributeButton, setDisplayReattributeButton] =
    useState(false);

  React.useEffect(() => {
    const loadArticle = async () => {
      const articleFromApi = await axios.get(
        Config.BASE_URL + '/api/v2/article/' + id_article,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      setImageURL(articleFromApi.data.thumbnail);
      setContent(articleFromApi.data.content);
      setInputs(articleFromApi.data);
      setMailto(articleFromApi.data.mail_client);

      if (articleFromApi.data.id_status == 7) {
        setDisplayReattributeButton(true);
      }
    };

    const loadRedacteurs = async () => {
      const redacteursFromApi = await axios.get(
        Config.BASE_URL + '/api/v2/redacteurs',
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      var arr = Array.from(redacteursFromApi.data);
      var name = [];
      arr.forEach((el) => {
        name.push({label: el.name_redac, id: el.id_user});
      });
      setNameRedac(name);
    };

    loadArticle();
    loadRedacteurs();
    clearTimeout(timer.current);
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({...values, [name]: value}));
  };

  const handleHtml = (data) => {
    setInputs((values) => ({...values, ['content']: data}));
  };

  const [images, setImages] = React.useState([]);
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    setInputs((values) => ({
      ...values,
      ['thumbnail_upload']: {
        data: imageList[0].data_url,
        name: imageList[0].file.name,
      },
    }));
  };

  const timer = React.useRef();

  const styleFab3 = {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  };

  var display = 'none';
  if (isError) {
    display = 'flex';
  }

  const removeImg = () => {
    setImages([]);
    setInputs((values) => ({
      ...values,
      ['thumbnail_upload']: '',
    }));
  };

  const handleButtonReattribute = (e) => {
    setOpenDial(false);
    setOpenDialog(true);
  };

  const handleReattribute = async () => {
    setOpenDialog(false);

    if (actionReattribute == 'reattribute_article') {
      await axios
        .put(
          Config.BASE_URL + '/api/v2/article/reattribute',
          {inputs: inputs, redac: redac},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        )
        .then((response) => {
          if (response.data.success == 1) {
            setSuccessMsg(response.data.message);
            setOpenSuccess(true);
          } else {
            setOpenError(true);
            setIsError(true);
            setError(response.data.message);
          }
        });
    } else {
      await axios
        .put(Config.BASE_URL + '/api/v2/article/rewrite', inputs, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          if (response.data.success == 1) {
            setSuccessMsg(response.data.message);
            setOpenSuccess(true);
          } else {
            setOpenError(true);
            setIsError(true);
            setError(response.data.message);
          }
        });
    }
  };

  const [actionReattribute, setActionReattribute] = useState('');
  const [displayReattribute, setDisplayReattribute] = useState(false);

  const handleChangeAction = (e) => {
    setActionReattribute(e.target.value);

    if (e.target.value == 'reattribute_article') {
      setDisplayReattribute(true);
    }
  };

  const handleButtonClick = () => {
    setOpenDial(false);
    setTimeout(() => {
      axios
        .put(
          Config.BASE_URL + '/api/v2/article/validation',
          {inputs: inputs, user: loggedUser[0]},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        )
        .then((response) => {
          // console.log(response.data);
          if (response.data.success == 0) {
            setOpenError(true);
            setIsError(true);
            setError(response.data.message);
          } else {
            setSuccessMsg(response.data.message);
            setOpenSuccess(true);
          }
        });
    }, 1000);
  };

  const handleButtonClickCorrect = () => {
    setOpenDial(false);
    setTimeout(() => {
      axios
        .put(
          Config.BASE_URL + '/api/v2/article/refuse',
          {inputs: inputs, user: loggedUser[0]},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        )
        .then((response) => {
          if (response.data.success == 0) {
            setOpenError(true);
            setIsError(true);
            setError(response.data.message);
          } else {
            setSuccessMsg(response.data.message);
            setOpenSuccess(true);
          }
        });
    }, 1000);
  };

  const handleButtonClickSave = async () => {
    setOpenDial(false);
    await axios
      .put(
        Config.BASE_URL + '/api/v2/article/save_admin',
        {inputs: inputs, user: loggedUser[0]},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((response) => {
        // console.log(response.data)
        if (response.data.success == 0) {
          setOpenError(true);
          setIsError(true);
          setError(response.data.message);
        } else {
          setSuccessMsg(response.data.message);
          setOpenSuccess(true);
        }
      });
  };

  const handleButtonContact = () => {
    var mail = 'mailto:' + mailto;
    const link = document.createElement('a');
    link.href = mail;
    link.target = '_blank';
    link.click();
  };

  return (
    <>
      <Grid item xs={12}>
        <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
          <Collapse in={openError}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{display: display}}
            >
              {error}
            </Alert>
          </Collapse>
          <Box component="form" noValidate sx={{mt: 1}}>
            <TextField
              margin="normal"
              fullWidth
              id="title"
              label="Titre"
              name="title"
              autoComplete="title"
              onChange={handleChange}
              value={inputs.title || ''}
            />
            <TextField
              margin="normal"
              fullWidth
              id="slug"
              label="Slug"
              name="slug"
              autoComplete="slug"
              onChange={handleChange}
              value={inputs.slug || ''}
            />
            <TextField
              margin="normal"
              fullWidth
              id="meta-titre"
              label="Méta titre"
              name="meta_title"
              autoComplete="meta-titre"
              onChange={handleChange}
              sx={{mt: 2}}
              value={inputs.meta_title || ''}
              inputProps={{maxLength: 60}}
              helperText={inputs.meta_title && `${inputs.meta_title.length}/60`}
            />

            <TextField
              multiline
              rows={5}
              margin="normal"
              fullWidth
              id="meta-description"
              label="Méta description"
              name="meta_description"
              autoComplete="meta-description"
              onChange={handleChange}
              value={inputs.meta_description || ''}
              inputProps={{maxLength: 160}}
              helperText={
                inputs.meta_description &&
                `${inputs.meta_description.length}/160`
              }
            />
            <Stack
              direction="row"
              spacing={2}
              sx={{mt: 2, alignItems: 'center', mb: 2}}
            >
              <Typography>Thumbnail (Poids max : 1 Mo) :</Typography>

              <ImageUploading
                value={images}
                onChange={onChange}
                dataURLKey="data_url"
                acceptType={['jpg', 'png']}
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                }) => (
                  <div className="upload__image-wrapper">
                    <Button
                      variant="contained"
                      component="label"
                      onClick={onImageUpload}
                      sx={{mr: 3}}
                    >
                      Upload
                    </Button>
                    {imageList.map((image, index) => (
                      // <CustomUpload
                      //   key={index}
                      //   url={image.data_url}
                      // />
                      <div key={index} className="image-item">
                        <img src={image.data_url} alt="" width="300" />
                        <div className="image-item__btn-wrapper">
                          <Button
                            variant="outlined"
                            color="error"
                            component="label"
                            onClick={removeImg}
                            size="small"
                          >
                            Supprimer
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </ImageUploading>
              {imagePreview === '' && (
                <Button variant="contained" onClick={() => {setOpenImagesApi(true)}} sx={{ fontSize: '11px'}}> Banques d'images</Button>
              )}
              {imagePreview !== '' && (
                <>
                  <img src={imagePreview} alt="" width="300" />

                  <Button
                    variant="outlined"
                    color="error"
                    component="label"
                    onClick={() => {setImagePreview(''); setInputs((values) => ({
                      ...values,
                      ['thumbnail_upload']: '',
                    })); }}
                    size="small"
                    sx={{ fontSize: '11px'}}
                  >
                    Supprimer
                  </Button>
                </>
              )}
            </Stack>
            {inputs.thumbnail && (
              <Typography>
                Actuellement :
                <a href={imageURL} target="_blank">
                  Voir l'image
                </a>
              </Typography>
            )}
            {/* <Editor content={inputs.content} /> */}
            <Editor
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={content}
              onEditorChange={(content) => handleHtml(content)}
              name="content"
              init={{
                height: 700,
                images_upload_url: Config.BASE_URL + '/api/v2/upload',
                image_dimensions: false,
                image_description: false,
                object_resizing: false,
                menubar: false,
                plugins: [
                  'advlist',
                  'anchor',
                  'autolink',
                  'help',
                  'image',
                  'link',
                  'lists',
                  'searchreplace',
                  'table',
                  'wordcount',
                  'preview',
                  'quickbars',
                  'autosave',
                  'iframe',
                ],
                quickbars_insert_toolbar: false,
                quickbars_selection_toolbar:
                  'bold italic underline | blocks | bullist numlist | blockquote quicklink',
                quickbars_image_toolbar: false,
                toolbar:
                  'undo redo | blocks | ' +
                  'bold italic underline blockquote' +
                  '| bullist numlist | ' +
                  '| table image preview restoredraft wordcount iframe',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } img {max-width: 1200px;}',
                table_toolbar:
                  'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                table_appearance_options: false,
                table_advtab: false,
                table_cell_advtab: false,
                table_row_advtab: false,
                autosave_interval: '30s',
              }}
            />
            <TextField
              fullWidth
              multiline
              rows={8}
              id="brief"
              label="Brief du client"
              name="brief"
              autoComplete="brief"
              sx={{mt: 2}}
              value={inputs.brief || ''}
            />
            <TextField
              fullWidth
              multiline
              rows={5}
              id="comment"
              label="Commentaire manager"
              name="comment"
              autoComplete="comment"
              onChange={handleChange}
              sx={{mt: 2}}
              value={inputs.comment || ''}
            />
            <TextField
              disabled
              fullWidth
              multiline
              rows={5}
              id="comment_corrector"
              label="Commentaire correcteur"
              name="comment_corrector"
              autoComplete="comment_corrector"
              onChange={handleChange}
              sx={{mt: 2}}
              value={inputs.comment_corrector || ''}
            />

            <TextField
              fullWidth
              multiline
              rows={5}
              id="motif_refus"
              label="Motif de refus"
              name="motif_refus"
              autoComplete="motif_refus"
              onChange={handleChange}
              sx={{mt: 2}}
              value={inputs.motif_refus || ''}
            />
          </Box>
        </Paper>
        <Fab aria-label="save" style={styleFab3}>
          <SpeedDial
            ariaLabel=""
            sx={{position: 'absolute', bottom: 0}}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={openDial}
          >
            <SpeedDialAction
              name="Sauvegarder"
              key="Sauvegarder"
              icon={<SaveIcon />}
              tooltipTitle="Sauvegarder"
              onClick={handleButtonClickSave}
            />
            <SpeedDialAction
              name="Valider"
              key="Valider"
              icon={<AddTaskIcon />}
              tooltipTitle="Valider"
              onClick={handleButtonClick}
            />
            <SpeedDialAction
              name="Refuser"
              key="Refuser"
              icon={<BlockIcon />}
              tooltipTitle="Refuser"
              onClick={handleButtonClickCorrect}
            />
            <SpeedDialAction
              name="Banques d'images"
              key="Banques d'images"
              icon={<PhotoLibraryIcon />}
              tooltipTitle="Banques d'images"
              onClick={() => {setOpenImagesApi(true)}}
            />


            {displayReattributeButton && (
              <SpeedDialAction
                name="Gérer la réattribution"
                key="Gérer la réattribution"
                icon={<AirlineStopsIcon />}
                tooltipTitle="Gérer la réattribution"
                onClick={handleButtonReattribute}
              />
            )}

            {displayReattributeButton && (
              <SpeedDialAction
                name="Contacter le client"
                key="Contacter le client"
                icon={<MailOutlineIcon />}
                tooltipTitle="Contacter le client"
                onClick={handleButtonContact}
              />
            )}
          </SpeedDial>
        </Fab>
      </Grid>
      <Snackbar
        open={openSuccess}
        autoHideDuration={4000}
        onClose={handleCloseSuccess}
        action={action}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{width: '100%'}}
        >
          {successMsg}
        </Alert>
      </Snackbar>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Choix</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choisir l'action à effectuer pour cette article
          </DialogContentText>

          <FormControl fullWidth sx={{mt: 3, mb: 3}}>
            <InputLabel id="action-select-label">Action</InputLabel>
            <Select
              labelId="action-select-label"
              id="action-select"
              value={actionReattribute}
              label="Action"
              onChange={handleChangeAction}
            >
              <MenuItem value={'reattribute_article'}>Réattribuer</MenuItem>
              <MenuItem value={'go_to_redac'}>Renvoyer en rédaction</MenuItem>
            </Select>
          </FormControl>

          {displayReattribute && (
            <Autocomplete
              value={redac}
              onChange={(event, newValue) => {
                setRedac(newValue);
              }}
              id="combo-box-redac"
              options={nameRedac}
              sx={{mb: 5}}
              renderInput={(params) => (
                <TextField fullWidth {...params} label="Rédacteurs" />
              )}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button onClick={handleReattribute}>Valider</Button>
        </DialogActions>
      </Dialog>
      <ImagesApi idArticle={inputs.id_article} open={openImagesApi} handleClose={setOpenImagesApi} post_title={inputs.title} editorRef={editorRef} setImagePreview={setImagePreview} setInputs={setInputs} />
    </>
  );
}

export default function ViewArticle() {
  return <Layout children={<ViewArticleContent />} />;
}
