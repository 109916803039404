import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {Link as LinkRouter} from "react-router-dom"
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import ListIcon from '@mui/icons-material/List';

export default function DeployList(props) {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    open ? setOpen(false) : setOpen(true)
  };

  const linkStyle = {
    textDecoration: 'none',
    color: 'rgba(0,0,0,0.87)'
  };

  return (
    <div>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <ReceiptIcon />
        </ListItemIcon>
        <ListItemText primary="Facturation" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>

        { props.premium == 'false' && (
        <LinkRouter to="/facturation" style={linkStyle}>
            <ListItemButton sx={{pl: 4}}>
                <ListItemIcon>
                  <ListIcon />
                </ListItemIcon>
              <ListItemText primary="Factures" />
            </ListItemButton>
        </LinkRouter>
        )}

          {props.display && (
            <LinkRouter to="/facturation/settings" style={linkStyle}>
              <ListItemButton sx={{pl: 4}}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Configuration" />
              </ListItemButton>
            </LinkRouter>
          )}

          

        </List>
      </Collapse>
    </div>
  );
}