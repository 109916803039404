import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useState} from 'react';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import Config from '../config.json';
import {Alert} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Papa from 'papaparse';
import Autocomplete from '@mui/material/Autocomplete';
import InfoIcon from '@mui/icons-material/Info';
import {useOutletContext} from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import Checkbox from '@mui/material/Checkbox';
import Layout from "../components/Layout"

function AddArticleContent() {
  const loggedUser = useOutletContext();

  var [inputs, setInputs] = useState({
    ['nl']: 'false',
    ['language']: 'false',
    ['time_limit']: 'false',
  });
  const [openError, setOpenError] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [openSuccess, setOpenSuccess] = useState(false);
  const [status, setStatus] = useState([]);
  const [statusSingle, setStatusSingle] = useState('');
  const [slug, setSlug] = useState('');
  const [parsedData, setParsedData] = useState([]);
  const [parsedDataLast, setParsedDataLast] = useState([]);
  const [lots, setLots] = useState([]);
  const [lot, setLot] = useState('');
  const [redacteurs, setRedacteurs] = useState([]);
  const [redacteur, setRedacteur] = useState(1);
  const [clients, setClients] = useState([]);
  const [tags, setTags] = useState([]);
  const [nameRedac, setNameRedac] = useState([]);
  const [checked, setChecked] = React.useState(true);

  var display = 'none';
  var loading = 'none';
  var show = 'flex';

  if (isError) {
    display = 'flex';
  }

  if (isLoading) {
    loading = 'flex';
    show = 'none';
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      axios
        .post(Config.BASE_URL + '/api/v2/article/save', inputs, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          if (response.data.success == 0) {
            setError(response.data.message);
            setIsError(true);
            setOpenError(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setSuccessMsg(response.data.message);
            setOpenSuccess(true);
            setInputs([]);
            setSlug('');
            setRedacteur('');
            setStatusSingle('');
            setLot('');
            setValueInput('');
          }
        });
    }, 1000);
  };

  function string_to_slug(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    var to = 'aaaaeeeeiiiioooouuuunc------';
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    if (str[str.length - 1] == '-') {
      // remove - if last pos
      str = str.substring(0, str.length - 1);
    }

    return str;
  }

  const handleChange = (event) => {
    setOpenError(false);
    if (event.target.name == 'titre') {
      var slufigy = string_to_slug(event.target.value);
      setSlug(slufigy);
      setInputs((values) => ({...values, ['slug']: slufigy}));
    }
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({...values, [name]: value}));
  };

  const handleStatus = (e) => {
    setStatusSingle(e.target.value);
    setOpenError(false);
    setInputs((values) => ({...values, [e.target.name]: e.target.value}));
  };

  const handleLot = (e) => {
    setLot(e.target.value);
    setOpenError(false);
    setInputs((values) => ({...values, [e.target.name]: e.target.value}));
  };

  const handleRedacteur = (e) => {
    setRedacteur(e.target.value);
    setOpenError(false);
    setInputs((values) => ({...values, [e.target.name]: e.target.value}));
  };

  const handleSlug = (e) => {
    setSlug(e.target.value);
    setOpenError(false);
    setInputs((values) => ({...values, [e.target.name]: e.target.value}));
  };

  React.useEffect(() => {
    const fetchStatus = async () => {
      const statusFromApi = await axios.get(
        Config.BASE_URL + '/api/v2/status',
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      // console.log(statusFromApi.data)
      setStatus(statusFromApi.data);
    };

    const loadLots = async () => {
      const lotsFromApi = await axios.get(Config.BASE_URL + '/api/v2/lots', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      setLots(lotsFromApi.data);
    };

    const loadRedacteurs = async () => {
      const redacteursFromApi = await axios.get(
        Config.BASE_URL + '/api/v2/redacteurs',
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      setRedacteurs(redacteursFromApi.data);

      var arr = Array.from(redacteursFromApi.data);
      var name = [];
      arr.forEach((el) => {
        name.push({label: el.name_redac, id: el.id_user});
      });
      setNameRedac(name);
    };

    const loadClients = async () => {
      const clientsFromApi = await axios.get(
        Config.BASE_URL + '/api/v2/clients',
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      // console.log(clientsFromApi.data)
      setClients(clientsFromApi.data);
    };

    const loadTags = async () => {
      const tagsFromApi = await axios.get(Config.BASE_URL + '/api/v2/tags', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      // console.log(tagsFromApi.data)
      var arr = Array.from(tagsFromApi.data);
      var nametag = [];
      arr.forEach((el) => {
        nametag.push({label: el.tag, id: el.id_tag});
      });
      setTags(nametag);
    };

    loadTags();
    loadClients();
    loadRedacteurs();
    loadLots();
    fetchStatus();
  }, []);

  const [images, setImages] = React.useState([]);
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList[0].file.name);
    setImages(imageList);
    setInputs((values) => ({
      ...values,
      ['thumbnail_upload']: {
        data: imageList[0].data_url,
        name: imageList[0].file.name,
      },
    }));
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSuccess}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  // const onDownload = () => {
  //   const link = document.createElement("a");
  //   link.download = `import-articles.csv`;
  //   link.href = Config.BASE_URL + "/import-articles.csv";
  //   link.click();
  // };

  const handleImport = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      comments: '//',
      transformHeader: function (h) {
        return h.toLowerCase();
      },
      complete: function (results) {
        setParsedData(results.data);
      },
    });
  };

  const handleImportLast = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      transformHeader: function (h) {
        h = h.replace(/\s+/g, '_');
        return h.toLowerCase();
      },
      complete: function (results) {
        setParsedDataLast(results.data);
      },
    });
  };

  const handleSubmitImportLast = (event) => {
    event.preventDefault();
    //console.log(parsedDataLast)
    setIsLoading(true);
    setTimeout(() => {
      axios
        .post(Config.BASE_URL + '/api/v2/article/importlast', parsedDataLast, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          //console.log(response.data)
          if (response.data.success == 0) {
            setError(response.data.message);
            setIsError(true);
            setOpenError(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setSuccessMsg(response.data.message);
            setOpenSuccess(true);
            setParsedData([]);
          }
        });
    }, 1000);
  };

  const handleSubmitImport = (event) => {
    event.preventDefault();
    // console.log(parsedData)
    setIsLoading(true);
    setTimeout(() => {
      axios
        .post(Config.BASE_URL + '/api/v2/article/import', parsedData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          //console.log(response.data)
          if (response.data.success == 0) {
            setError(response.data.message);
            setIsError(true);
            setOpenError(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setSuccessMsg(response.data.message);
            setOpenSuccess(true);
            setParsedData([]);
          }
        });
    }, 1000);
  };

  const handleSubmitList = (e) => {
    e.preventDefault();
    // console.log(inputs)
    setIsLoading(true);
    setTimeout(() => {
      axios
        .post(Config.BASE_URL + '/api/v2/article/addList', inputs, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          //console.log(response.data)
          if (response.data.success == 0) {
            setError(response.data.message);
            setIsError(true);
            setOpenError(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setSuccessMsg(response.data.message);
            setOpenSuccess(true);
            setInputs([]);
          }
        });
    }, 1000);
  };

  const [language, setLanguage] = useState('false');
  const handleLanguage = (e) => {
    setLanguage(e.target.value);
    setOpenError(false);
    setInputs((values) => ({...values, [e.target.name]: e.target.value}));
  };

  const [nl, setNl] = useState('false');
  const handleNl = (e) => {
    setNl(e.target.value);
    setOpenError(false);
    setInputs((values) => ({...values, [e.target.name]: e.target.value}));
  };

  const [valueInput, setValueInput] = React.useState('');

  const [qualite, setQualite] = useState(2);
  const handleQualite = (e) => {
    setQualite(e.target.value);
    setInputs((values) => ({...values, [e.target.name]: e.target.value}));
  };

  const [tag, setTag] = useState('');
  const handleTag = (e) => {
    setTag(e.target.value);
    setInputs((values) => ({...values, [e.target.name]: e.target.value}));
  };

  const handleClearClick = () => {
    setLot('');
    setInputs((values) => ({...values, ['id_lot']: ''}));
    setOpenError(false);
  };

  const options = [
    {label: 'The Godfather', id: 1},
    {label: 'Pulp Fiction', id: 2},
  ];

  const handleChecked = (event) => {
    setChecked(event.target.checked);
    setInputs((values) => ({
      ...values,
      [event.target.name]: event.target.checked,
    }));
  };

  return (
    <>
      <Grid item xs={12}>
        <Accordion sx={{mb: 2}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Ajouter manuellement</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Collapse in={openError}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenError(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{display: display, mb: 2}}
              >
                {error}
              </Alert>
            </Collapse>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{mt: 1}}
            >
              <Stack
                direction="row"
                spacing={2}
                sx={{mb: 2, alignItems: 'center'}}
              >
                Attribué à :
                <Box sx={{minWidth: 200, ml: 3, mr: 3}}>
                  <FormControl fullWidth>
                    <InputLabel id="lot-select-label">N° de lot</InputLabel>
                    <Select
                      labelId="lot-select-label"
                      required
                      id="id_lot"
                      label="N° de lot"
                      name="id_lot"
                      onChange={handleLot}
                      value={lot || ''}
                      endAdornment={
                        <IconButton
                          sx={{display: lot ? '' : 'none', mr: 2}}
                          onClick={handleClearClick}
                        >
                          <ClearIcon />
                        </IconButton>
                      }
                    >
                      {lots.map((l) => {
                        if (l.id_lot !== '1') {
                          return (
                            <MenuItem key={l.id_lot} value={l.id_lot}>
                              {l.id_lot} - {l.title}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                </Box>
                Ou
                <Autocomplete
                  name="client"
                  onChange={(e, newValue) => {
                    setInputs((values) => ({
                      ...values,
                      ['client']: newValue,
                    }));
                  }}
                  value={inputs.client || ''}
                  id="controllable-client"
                  options={clients}
                  sx={{width: 300}}
                  renderInput={(params) => (
                    <TextField {...params} label="Email" />
                  )}
                />
              </Stack>
              Limiter la rédaction à 48 heures ?
              <Checkbox
                name="time_limit"
                checked={checked}
                onChange={handleChecked}
                inputProps={{'aria-label': 'controlled'}}
              />
              <TextField
                required
                fullWidth
                id="titre"
                label="Titre"
                name="titre"
                autoComplete="titre"
                onChange={handleChange}
                value={inputs.titre || ''}
              />
              <TextField
                margin="normal"
                fullWidth
                id="slug"
                label="Slug"
                name="slug"
                autoComplete="slug"
                onChange={handleSlug}
                value={slug || ''}
              />
              <Stack direction="row" spacing={2} sx={{mt: 1}}>
                <FormControl fullWidth>
                  <InputLabel id="statut-select-label">Statut</InputLabel>
                  <Select
                    labelId="statut-select-label"
                    required
                    id="statut"
                    label="statut"
                    name="statut"
                    onChange={handleStatus}
                    value={statusSingle || ''}
                  >
                    {status.map((s) => (
                      <MenuItem key={s.id_status} value={s.id_status}>
                        {s.status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Autocomplete
                  name="id_user"
                  renderOption={(props, option) => {
                    // console.log(option)
                    return (
                      <li {...props} key={option.id}>
                        {option.label}
                      </li>
                    );
                  }}
                  onChange={(e, newValue) => {
                    //console.log(newValue)
                    setInputs((values) => ({
                      ...values,
                      ['id_user']: newValue,
                    }));
                  }}
                  value={inputs.id_user || ''}
                  id="controllable-client"
                  options={nameRedac}
                  sx={{width: 300}}
                  renderInput={(params) => (
                    <TextField {...params} label="Redacteur" />
                  )}
                />
                {/*
                        <FormControl fullWidth>
                          <InputLabel id="redacteur-select-label">
                            Rédacteur
                          </InputLabel>
                          <Select
                            labelId="redacteur-select-label"
                            required
                            id="id_user"
                            label="Rédacteur"
                            name="id_user"
                            onChange={handleRedacteur}
                            value={redacteur}
                          >
                            {redacteurs.map((r) => (
                              <MenuItem key={r.id_user} value={r.id_user}>
                                {r.firstname} {r.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl> */}
              </Stack>
              {inputs.client && (
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{mt: 2, alignItems: 'center'}}
                >
                  <TextField
                    fullWidth
                    id="site"
                    label="Site"
                    name="site"
                    autoComplete="site"
                    onChange={handleChange}
                    placeholder="https://votre-site.com"
                    value={inputs.site || ''}
                    helperText={
                      inputs.site &&
                      "Attention, cette option ne permet pas l'import direct"
                    }
                  />
                  <FormControl fullWidth>
                    <InputLabel id="language-select-label">
                      Anglais ?
                    </InputLabel>
                    <Select
                      labelId="language-select-label"
                      required
                      id="language"
                      label="Anglais ?"
                      name="language"
                      onChange={handleLanguage}
                      value={language}
                    >
                      <MenuItem value={'true'}>Oui</MenuItem>
                      <MenuItem value={'false'}>Non</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="nl-select-label">Nextlevel ?</InputLabel>
                    <Select
                      labelId="nl-select-label"
                      required
                      id="nl"
                      label="Nextlevel ?"
                      name="nl"
                      onChange={handleNl}
                      value={nl}
                    >
                      <MenuItem value={'true'}>Oui</MenuItem>
                      <MenuItem value={'false'}>Non</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="qualite-select-label">
                      Qualité ? *
                    </InputLabel>
                    <Select
                      labelId="qualite-select-label"
                      required
                      id="qualite"
                      label="Qualité ? *"
                      name="qualite"
                      onChange={handleQualite}
                      value={qualite}
                    >
                      <MenuItem value={3}>Premium</MenuItem>
                      <MenuItem value={2}>Classic</MenuItem>
                    </Select>
                  </FormControl>
                  <Autocomplete
                    name="id_tag"
                    fullWidth
                    renderOption={(props, option) => {
                      // console.log(option)
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    onChange={(e, newValue) => {
                      //console.log(newValue)
                      setInputs((values) => ({
                        ...values,
                        ['id_tag']: newValue,
                      }));
                    }}
                    value={inputs.id_tag || ''}
                    id="id_tag"
                    options={tags}
                    renderInput={(params) => (
                      <TextField {...params} label="Thématique" />
                    )}
                  />
                </Stack>
              )}
              <TextField
                fullWidth
                id="comment"
                label="Commentaire"
                name="comment"
                autoComplete="comment"
                onChange={handleChange}
                sx={{mt: 2}}
                value={inputs.comment || ''}
              />
              <LoadingButton
                type="submit"
                fullWidth
                loading={isLoading}
                variant="contained"
                sx={{mt: 2}}
              >
                Ajouter
              </LoadingButton>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{mb: 2}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Importer CSV</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction="row" sx={{mb: 2}}>
              <InfoIcon sx={{mr: 2}} /> 1 import = 1 lot. Pour les options
              anglais, qualité et nextlevel seule la première ligne servira de
              base pour le lot.
            </Stack>
            <Typography>
              Fichier source :{' '}
              <a
                className="download-link"
                target="_blank"
                href="https://docs.google.com/spreadsheets/d/188YbVw4BM4vHgIeFUlyGdfi_Okj44uSIO4a8gM1B0Zs/edit?usp=sharing"
              >
                Voir
              </a>
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmitImport}
              noValidate
              sx={{mt: 1}}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                name="file"
                type="file"
                id="file"
                inputProps={{accept: '.csv'}}
                onChange={handleImport}
              />
              <LoadingButton
                type="submit"
                fullWidth
                loading={isLoading}
                variant="contained"
                sx={{mt: 2}}
              >
                Importer
              </LoadingButton>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{mb: 2}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Ajouter une liste d'articles</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>⚠️ Un titre par ligne</Typography>
            <Stack
              direction="row"
              spacing={2}
              sx={{mb: 2, alignItems: 'center', mt: 2}}
            >
              Attribué à :
              <Box sx={{minWidth: 200, ml: 3, mr: 3}}>
                <FormControl fullWidth>
                  <InputLabel id="lot-select-label">N° de lot</InputLabel>
                  <Select
                    labelId="lot-select-label"
                    required
                    id="id_lot"
                    label="N° de lot"
                    name="id_lot"
                    onChange={handleLot}
                    value={lot || ''}
                    endAdornment={
                      <IconButton
                        sx={{display: lot ? '' : 'none', mr: 2}}
                        onClick={handleClearClick}
                      >
                        <ClearIcon />
                      </IconButton>
                    }
                  >
                    {lots.map((l) => {
                      if (l.id_lot !== '1') {
                        return (
                          <MenuItem key={l.id_lot} value={l.id_lot}>
                            {l.id_lot} - {l.title}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              </Box>
              Ou
              <Autocomplete
                name="client"
                onChange={(e, newValue) => {
                  setValueInput(newValue);
                  setInputs((values) => ({
                    ...values,
                    ['client']: newValue,
                  }));
                }}
                value={valueInput}
                id="controllable-client"
                options={clients}
                sx={{width: 300}}
                renderInput={(params) => (
                  <TextField {...params} label="Email" />
                )}
              />
            </Stack>
            Limiter la rédaction à 48 heures ?
            <Checkbox
              checked={checked}
              onChange={handleChecked}
              inputProps={{'aria-label': 'controlled'}}
            />
            <Stack direction="row" spacing={2}>
              <Autocomplete
                fullWidth
                name="id_user"
                renderOption={(props, option) => {
                  // console.log(option)
                  return (
                    <li {...props} key={option.id}>
                      {option.label}
                    </li>
                  );
                }}
                onChange={(e, newValue) => {
                  setInputs((values) => ({
                    ...values,
                    ['id_user']: newValue,
                  }));
                }}
                value={inputs.id_user || ''}
                id="controllable-client"
                options={nameRedac}
                renderInput={(params) => (
                  <TextField {...params} label="Redacteur" />
                )}
              />
            </Stack>
            {valueInput && (
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                sx={{mt: 2}}
              >
                <TextField
                  fullWidth
                  id="site"
                  label="Site"
                  name="site"
                  autoComplete="site"
                  onChange={handleChange}
                  placeholder="https://votre-site.com"
                  value={inputs.site || ''}
                  helperText={
                    inputs.site &&
                    "Attention, cette option ne permet pas l'import direct"
                  }
                />

                <FormControl fullWidth>
                  <InputLabel id="language-select-label">Anglais ?</InputLabel>
                  <Select
                    labelId="language-select-label"
                    required
                    id="language"
                    label="Anglais ?"
                    name="language"
                    onChange={handleLanguage}
                    value={language}
                  >
                    <MenuItem value={'true'}>Oui</MenuItem>
                    <MenuItem value={'false'}>Non</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="nl-select-label">Nextlevel ?</InputLabel>
                  <Select
                    labelId="nl-select-label"
                    required
                    id="nl"
                    label="Nextlevel ?"
                    name="nl"
                    onChange={handleNl}
                    value={nl}
                  >
                    <MenuItem value={'true'}>Oui</MenuItem>
                    <MenuItem value={'false'}>Non</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="qualite-select-label">Qualité ? *</InputLabel>
                  <Select
                    labelId="qualite-select-label"
                    required
                    id="qualite"
                    label="Qualité ? *"
                    name="qualite"
                    onChange={handleQualite}
                    value={qualite}
                  >
                    <MenuItem value={3}>Premium</MenuItem>
                    <MenuItem value={2}>Classic</MenuItem>
                  </Select>
                </FormControl>

                <Autocomplete
                  name="id_tag"
                  fullWidth
                  renderOption={(props, option) => {
                    // console.log(option)
                    return (
                      <li {...props} key={option.id}>
                        {option.label}
                      </li>
                    );
                  }}
                  onChange={(e, newValue) => {
                    //console.log(newValue)
                    setInputs((values) => ({
                      ...values,
                      ['id_tag']: newValue,
                    }));
                  }}
                  value={inputs.id_tag || ''}
                  id="id_tag"
                  options={tags}
                  renderInput={(params) => (
                    <TextField {...params} label="Thématique" />
                  )}
                />
              </Stack>
            )}
            <Box component="form" onSubmit={handleSubmitList} noValidate>
              <TextField
                margin="normal"
                fullWidth
                id="articles_list"
                label="Liste"
                name="articles_list"
                autoComplete="articles_list"
                placeholder="ex : Comment obtenir… en X temps ?"
                multiline
                rows={10}
                onChange={handleChange}
              />

              <LoadingButton
                type="submit"
                fullWidth
                loading={isLoading}
                variant="contained"
                sx={{mt: 2}}
                onChange={handleChange}
              >
                Ajouter la liste
              </LoadingButton>
            </Box>
          </AccordionDetails>
        </Accordion>
        {loggedUser[0].id_role == 1 && (
          <Accordion sx={{mb: 2}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Importer anciens articles</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                component="form"
                onSubmit={handleSubmitImportLast}
                noValidate
                sx={{mt: 1}}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="file"
                  type="file"
                  id="file"
                  inputProps={{accept: '.csv'}}
                  onChange={handleImportLast}
                />
                <LoadingButton
                  type="submit"
                  fullWidth
                  loading={isLoading}
                  variant="contained"
                  sx={{mt: 2}}
                >
                  Importer
                </LoadingButton>
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
      <Snackbar
        open={openSuccess}
        autoHideDuration={4000}
        onClose={handleCloseSuccess}
        action={action}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{width: '100%'}}
        >
          {successMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default function AddArticle() {
  return <Layout children={<AddArticleContent />} />;
}
