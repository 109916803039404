import {Route, BrowserRouter, Routes} from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
import PrivateRoutes from '../components/PrivateRoutes';
import Error404 from '../pages/Error404';
import AddArticles from '../pages/AddArticles';
import WriteArticle from '../pages/WriteArticle';
import CorrectArticle from '../pages/CorrectArticle';
import ViewArticle from '../pages/ViewArticle';
import Dashboard from '../pages/Dashboard';
import Api from '../pages/Api';
import React, {Suspense, lazy} from 'react';
import Layout from './Layout';
import CustomBackdrop from './CustomBackdrop';

const Users = React.lazy(() => import('../pages/Users'));

const Articles = React.lazy(() => import('../pages/Articles'));

const ArticlesGC = React.lazy(() => import('../pages/ArticlesGC'));

const CheckArticle = React.lazy(() => import('../pages/CheckArticle'));

const Facturation = React.lazy(() => import('../pages/Facturation'));

const FacturationSettings = React.lazy(() =>
  import('../pages/FacturationSettings'),
);

const Account = React.lazy(() => import('../pages/Account'));

export default function Router() {
  return (
    <div>
      <div>
        <BrowserRouter>
          <Suspense fallback={<CustomBackdrop />}>
            <Routes>
              <Route element={<PrivateRoutes />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/account" element={<Account />} />
                <Route path="/facturation" element={<Facturation />} />
                <Route
                  path="/facturation/settings"
                  element={<FacturationSettings />}
                />
                <Route path="/users" element={<Users />} />
                <Route path="/articles" element={<Articles />} />
                <Route path="/articles/list" element={<ArticlesGC />} />
                <Route path="/article/add" element={<AddArticles />} />
                <Route
                  path="/article/write/:id_article"
                  element={<WriteArticle />}
                />
                <Route
                  path="/article/correct/:id_article"
                  element={<CorrectArticle />}
                />
                <Route
                  path="/article/view/:id_article"
                  element={<ViewArticle />}
                />
                <Route
                  path="/article/check/:id_article"
                  element={<CheckArticle />}
                />
                <Route path="/api" element={<Api />} />
              </Route>
              <Route path="/" element={<LoginPage />} />
              <Route path="*" element={<Error404 />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </div>
    </div>
  );
}
