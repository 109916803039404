import React, {useState, useEffect} from 'react';
import {Outlet, Navigate} from 'react-router-dom';
import axios from 'axios';
import Config from '../config.json';
import {useNavigate} from 'react-router-dom';

const PrivateRoutes = () => {
  const [loggedUser, setLoggedUser] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getUser = async () => {
      var response = await axios.get(Config.BASE_URL + '/api/v2/checkToken', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.data.success == 1) {
        setLoggedUser(response.data.user);
      } else {
        navigate('/');
      }
    };

    getUser();
  }, []);

    return localStorage.getItem('token') ? <Outlet context={[loggedUser]}/> : <Navigate to="/" />;
};

export default PrivateRoutes;
