import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import axios from "axios";
import { Alert } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";
import Config from "../config.json";
import LoadingButton from '@mui/lab/LoadingButton';



const theme = createTheme({
  spacing: 2
});

export default function SignIn() {
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const handleChange = (event) => {
      setOpen(false)
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({...values, [name]: value}));
  }
  

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    setTimeout(() => {
      axios.post(Config.BASE_URL + '/api/v2/login', inputs).then( (response) => {
        // console.log(response.data)
          if(response.data.success == 0){
            setError(response.data.message)
            setIsError(true)
            setOpen(true)
            setIsLoading(false);
          }else{
            localStorage.setItem('token', response.data.token)
            navigate("/dashboard");
          }
      })
    }, 1000);
    
  }

  var display = 'none'

  if(isError){
    display = "flex"
  }


  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 25
          }}
        >
          <img src="https://www.savage-note.com/wp-content/uploads/2022/12/cropped-savage-note-logo.png" className='p-50'/>
          <Typography component="h1" variant="h5">
            Connexion
          </Typography>
          <Collapse in={open}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{mb: 2, display: display, marginTop: 7}}
            >
              {error}
            </Alert>
          </Collapse>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Adresse mail"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
            />
            <LoadingButton
              type="submit"
              fullWidth
              loading={isLoading}
              variant="contained"
              sx={{mt: 4}}
            >
              Se connecter
            </LoadingButton>
          </Box>
        </Box>

      </Container>
    </ThemeProvider>
  );
}