import { Editor } from '@tinymce/tinymce-react';

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce';
// DOM model
import 'tinymce/models/dom/model'
// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/image';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';

// importing plugin resources
import 'tinymce/plugins/emoticons/js/emojis';

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css';
// import '../../assets/langs/fr_FR'
import Config from "../../config.json"

export default function BundledEditor(props) {

  // const lang = 'http://localhost:8888/savage-dreams/src/assets/langs/fr_FR';

  const {init, ...rest} = props;

  const image_upload_handler = (blobInfo, progress) => new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open('POST', Config.BASE_URL + '/api/v2/upload');
  
    xhr.upload.onprogress = (e) => {
      progress((e.loaded / e.total) * 100);
    };
  
    xhr.onload = () => {

      if(xhr.status === 428) {
        reject({message: 'Taille de l\'image non respectée (Largeur : min 1200px / max 1920px)'});
        return;
      }

      if(xhr.status === 455) {
        reject({message: 'Image trop lourde (Poids max : 1.5 Mo)'});
        return;
      }

      if (xhr.status === 403) {
        reject({ message: 'HTTP Error: ' + xhr.status, remove: true });
        return;
      }
  
      if (xhr.status < 200 || xhr.status >= 300) {
        reject('HTTP Error: ' + xhr.status);
        return;
      }
  
      const json = JSON.parse(xhr.responseText);
  
      if (!json || typeof json.location != 'string') {
        reject('Invalid JSON: ' + xhr.responseText);
        return;
      }
  
      resolve(json.location);
    };
  
    xhr.onerror = () => {
      reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
    };
    const formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());
  
    xhr.send(formData);
  });


function isUrl(text) {
    // Expression régulière pour matcher une URL
    const urlRegex = /^(?:https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

    if( urlRegex.test(text) || text.includes('<iframe')  ){
      return true;
    }

    return false
}

function getInfos(string){
  var srcIndex = string.indexOf('src="') + 5;

  var srcSubstring = string.substring(srcIndex);

  var closingQuoteIndex = srcSubstring.indexOf('"');

  var src = srcSubstring.substring(0, closingQuoteIndex);

  var heightIndex = string.indexOf('height="') + 8;

  var heightSubstring = string.substring(heightIndex);

  var closingHeightQuoteIndex = heightSubstring.indexOf('"');

  var height = heightSubstring.substring(0, closingHeightQuoteIndex)

  var widthIndex = string.indexOf('width="') + 7;

  var widthSubstring = string.substring(widthIndex);

  var closingWidthQuoteIndex = widthSubstring.indexOf('"');

  var width = widthSubstring.substring(0, closingWidthQuoteIndex);

  return{ src, height, width};
}
  
tinymce.PluginManager.add('iframe', (editor, url) => {

    const openDialog = () => editor.windowManager.open({
      title: 'Insérer iframe',
      body: {
        type: 'panel',
        items: [
          {
            type: 'input',
            name: 'src',
            label: 'Source',
            flex: true
          }
        ]
      },
      buttons: [
        {
          type: 'cancel',
          text: 'Fermer'
        },
        {
          type: 'submit',
          text: 'Sauvegarder',
          buttonType: 'primary'
        }
      ],
      onSubmit: (api) => {
        const data = api.getData();


        api.close()

        if(isUrl(data.src)){

          if(!data.src.includes('<iframe')){
            const url = new URL(data.src)
            var params = new URLSearchParams(url.search);
          }
         

          var src = data.src
          var id = ""
          var width = 560
          var height = 315
          var scrolling = "";

          if(data.src.includes('youtube') && !data.src.includes('embed') ){ // Si le lien fourni n'est pas déjà un embed
            id = params.get('v')
            src = 'https://youtube.com/embed/' + id
          }else if(data.src.includes('youtu.be')){
            id = src.split('/')[3]
            src = 'https://youtube.com/embed/' + id
          }

          if(data.src.includes('instagram')){
            
            if(!data.src.includes('embed')){
              id = src.split('/')[4]
              src = 'https://instagram.com/p/' + id + '/embed'
            }
            width = 320
            height = 520 
          }

          if(data.src.includes('pinterest')){
            if(!data.src.includes('assets')){

              id = src.split('/')[4]
              src = 'https://assets.pinterest.com/ext/embed.html?id=' + id 

              width = 560
              height = 500 

            }else{

              var infos = getInfos(data.src)
              
              src = infos.src
              width = infos.width
              height = infos.height

            }
           
          }

          tinymce.activeEditor.execCommand('InsertHTML', false, '<iframe width="' + width + '" height="' + height + '"  src="' + src + '" title="" frameborder="0" ' + scrolling + ' ></iframe>');

            editor.notificationManager.open({
                text: 'Iframe importé',
                timeout: 5000,
                type: 'success'
            });

        }else{
            const notification = editor.notificationManager.open({
                text: 'Merci d\'entrer une URL valide',
                timeout: 5000,
                type: 'error',
            });
        }
      }
    });

    /* Add a button that opens a window */
    editor.ui.registry.addButton('iframe', {
        tooltip: 'Insérer iframe',
        icon: 'embed',
      onAction: () => {
        /* Open window */
        openDialog();
      }
    });
  });

  
  return (
    <Editor
      init={{
        ...init,
        skin: false,
        content_css: false,
        content_style: [contentCss, contentUiCss, init.content_style || ''].join('\n'),
        language: 'fr_FR',
        language_url: "/langs/fr_FR.js",
        images_upload_handler: image_upload_handler,
      }}
      {...rest}
    />
  );
  
}