import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListIcon from '@mui/icons-material/List';
import {Link as LinkRouter} from "react-router-dom"
import FeedIcon from '@mui/icons-material/Feed';
import AddIcon from '@mui/icons-material/Add';

export default function DeployList(props) {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    open ? setOpen(false) : setOpen(true)
  };

  const linkStyle = {
    textDecoration: 'none',
    color: 'rgba(0,0,0,0.87)'
  };

  return (
    <div>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <FeedIcon />
        </ListItemIcon>
        <ListItemText primary="Articles" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {!props.display_gc &&
            <LinkRouter to="/articles" style={linkStyle}>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <ListIcon />
                </ListItemIcon>
                <ListItemText primary="Liste" />
              </ListItemButton>
            </LinkRouter>}
          {props.display && (
            <LinkRouter to="/article/add" style={linkStyle}>
              <ListItemButton sx={{pl: 4}}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary="Ajouter" />
              </ListItemButton>
            </LinkRouter>
          )}

          {props.display_gc && (
            <LinkRouter to="/articles/list" style={linkStyle}>
              <ListItemButton sx={{pl: 4}}>
                <ListItemIcon>
                  <ListIcon />
                </ListItemIcon>
                <ListItemText primary="Mes articles" />
              </ListItemButton>
            </LinkRouter>
          )}
        </List>
      </Collapse>
    </div>
  );
}