import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {useOutletContext, useParams} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import axios from 'axios';
import Config from '../config.json';
import {
  Stack,
  TextField,
  Button,
  Fab,
  CircularProgress,
  Snackbar,
  Collapse,
  Tooltip
} from '@mui/material';
import ImageUploading from 'react-images-uploading';
import Editor from '../components/WYSIWYG/Editor';
import {useState} from 'react';
import {green} from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import AddTaskIcon from '@mui/icons-material/AddTask';
import BlockIcon from '@mui/icons-material/Block';
import Layout from "../components/Layout"
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import ImagesApi from '../components/ImagesApi';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CorrectArticleContent() {
  const editorRef = React.useRef(null);
  var [inputs, setInputs] = useState([]);

  const {id_article} = useParams();

  const loggedUser = useOutletContext();
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [content, setContent] = useState('')
  const [imagePreview, setImagePreview] = useState('');
  const [openImagesApi, setOpenImagesApi] = useState(false);


  const handleHtml = (data) => {
    setInputs((values) => ({...values, ['content']: data}));
  }

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSuccess}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  React.useEffect(() => {
    const loadArticle = async () => {
      const articleFromApi = await axios.get(
        Config.BASE_URL + '/api/v2/article/' + id_article,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      setImageURL(articleFromApi.data.thumbnail);
      setInputs(articleFromApi.data);
      setContent(articleFromApi.data.content);
    };
    loadArticle();
    clearTimeout(timer.current);


  }, []);


  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({...values, [name]: value}));
    setInputs((values) => ({...values, ['reason']: 'saved_by_redacteur'}));
  };

  const [images, setImages] = React.useState([]);
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    setInputs((values) => ({
      ...values,
      ['thumbnail_upload']: {
        data: imageList[0].data_url,
        name: imageList[0].file.name,
      },
    }));
  };

  const [loading, setLoading] = React.useState(false);
  const [loadingCorrect, setLoadingCorrect] = React.useState(false);
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [successCorrect, setSuccessCorrect] = React.useState(false);
  const [successSave, setSuccessSave] = React.useState(false);
  const timer = React.useRef();

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  const buttonSx2 = {
    ...(successCorrect && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  const buttonSx3 = {
    ...(successSave && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  const handleButtonClick = () => {
    // console.log(inputs)
    setSuccess(false);
    setLoading(true);
    setTimeout(() => {
      axios
        .put(Config.BASE_URL + '/api/v2/article/validation', {inputs: inputs, user: loggedUser[0] }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.success == 0) {
            setLoading(false)
            setOpenError(true);
            setIsError(true);
            setError(response.data.message);
          } else {
            setLoading(false);
            setSuccess(true);
            setSuccessMsg(response.data.message);
            setOpenSuccess(true);
          }
        });
    }, 1000);
  };

  const handleButtonClickCorrect = () => {
    setSuccessCorrect(false);
    setLoadingCorrect(true);
    setTimeout(() => {
      axios
        .put(Config.BASE_URL + '/api/v2/article/refuse', {inputs: inputs, user: loggedUser[0] }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })
        .then((response) => {
          // console.log(response.data)
          if (response.data.success == 0) {
            setLoading(false);
            setOpenError(true);
            setIsError(true);
            setError(response.data.message);
          } else {
            setLoadingCorrect(false);
            setSuccessCorrect(true);
            setSuccessMsg(response.data.message);
            setOpenSuccess(true);
          }
        });
    }, 1000);
  };

  const handleButtonClickSave = () => {
    setSuccessSave(false);
    setLoadingSave(true);
    setTimeout(() => {
      axios
        .put(
          Config.BASE_URL + '/api/v2/article/save_corrector',
          {inputs: inputs, user: loggedUser[0]},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        )
        .then((response) => {
          // console.log(response.data)
          if (response.data.success == 0) {
            setLoadingSave(false);
            setOpenError(true);
            setIsError(true);
            setError(response.data.message);
          } else {
            setLoadingSave(false);
            setSuccessSave(true);
            setSuccessMsg(response.data.message);
            setOpenSuccess(true);
          }
        });
    }, 1000);
  };


  const styleFab = {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  };

  const styleFab2 = {
    margin: 0,
    top: 'auto',
    right: 90,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  };

  const styleFab3 = {
    margin: 0,
    top: 'auto',
    right: 160,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  };

  const styleFab4 = {
    margin: 0,
    top: 'auto',
    right: 230,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  };

  var display = 'none';
  if (isError) {
    display = 'flex';
  }

const removeImg = () => {
  setImages([]);
  setInputs((values) => ({
    ...values,
    ['thumbnail_upload']: '',
  }));
  };
  
   

  return (
    <>
      <Grid item xs={12}>
        <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
          <Collapse in={openError}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{display: display}}
            >
              {error}
            </Alert>
          </Collapse>
          <Box component="form" noValidate sx={{mt: 1}}>
            <TextField
              margin="normal"
              fullWidth
              id="title"
              label="Titre"
              name="title"
              autoComplete="title"
              onChange={handleChange}
              value={inputs.title || ''}
            />
            <TextField
              margin="normal"
              fullWidth
              id="slug"
              label="Slug"
              name="slug"
              autoComplete="slug"
              onChange={handleChange}
              value={inputs.slug || ''}
            />
            <TextField
              margin="normal"
              fullWidth
              id="meta-titre"
              label="Méta titre"
              name="meta_title"
              autoComplete="meta-titre"
              onChange={handleChange}
              sx={{mt: 0}}
              value={inputs.meta_title || ''}
              inputProps={{maxLength: 60}}
              helperText={inputs.meta_title && `${inputs.meta_title.length}/60`}
            />

            <TextField
              multiline
              rows={4}
              margin="normal"
              fullWidth
              id="meta-description"
              label="Méta description"
              name="meta_description"
              autoComplete="meta-description"
              onChange={handleChange}
              value={inputs.meta_description || ''}
              inputProps={{maxLength: 160}}
              helperText={
                inputs.meta_description &&
                `${inputs.meta_description.length}/160`
              }
            />
            <Stack
              direction="row"
              spacing={2}
              sx={{mt: 2, alignItems: 'center', mb: 2}}
            >
              <Typography>Thumbnail (Poids max : 1 Mo) :</Typography>

              <ImageUploading
                value={images}
                onChange={onChange}
                dataURLKey="data_url"
                acceptType={['jpg', 'png']}
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                }) => (
                  <div className="upload__image-wrapper">
                    <Button
                      variant="contained"
                      component="label"
                      onClick={onImageUpload}
                      sx={{mr: 3}}
                    >
                      Upload
                    </Button>
                    {imageList.map((image, index) => (
                      // <CustomUpload
                      //   key={index}
                      //   url={image.data_url}
                      // />
                      <div key={index} className="image-item">
                        <img src={image.data_url} alt="" width="300" />
                        <div className="image-item__btn-wrapper">
                          <Button
                            variant="outlined"
                            color="error"
                            component="label"
                            onClick={removeImg}
                            size="small"
                          >
                            Supprimer
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </ImageUploading>
              {imagePreview === '' && (
                <Button variant="contained" onClick={() => {setOpenImagesApi(true)}} sx={{ fontSize: '11px'}}> Banques d'images</Button>
              )}
              {imagePreview !== '' && (
                <>
                  <img src={imagePreview} alt="" width="300" />

                  <Button
                    variant="outlined"
                    color="error"
                    component="label"
                    onClick={() => {setImagePreview(''); setInputs((values) => ({
                      ...values,
                      ['thumbnail_upload']: '',
                    })); }}
                    size="small"
                    sx={{ fontSize: '11px'}}
                  >
                    Supprimer
                  </Button>
                </>
              )}
            </Stack>
            {inputs.thumbnail && (
              <Typography>
                Actuellement :
                <a href={imageURL} target="_blank">
                  Voir l'image
                </a>
              </Typography>
            )}

            <Editor
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={content}
              onEditorChange={(content) => handleHtml(content)}
              name="content"
              init={{
                height: 700,
                images_upload_url: Config.BASE_URL + '/api/upload',
                image_dimensions: false,
                image_description: false,
                object_resizing: false,
                menubar: false,
                plugins: [
                  'advlist',
                  'anchor',
                  'autolink',
                  'help',
                  'image',
                  'link',
                  'lists',
                  'searchreplace',
                  'table',
                  'wordcount',
                  'preview',
                  'quickbars',
                  'autosave',
                  'iframe',
                  'image'
                ],
                quickbars_insert_toolbar: false,
                quickbars_selection_toolbar:
                  'bold italic underline | blocks | bullist numlist | blockquote quicklink',
                quickbars_image_toolbar: false,
                toolbar:
                  'undo redo | blocks | ' +
                  'bold italic underline blockquote' +
                  '| bullist numlist | ' +
                  '| table preview restoredraft wordcount image iframe',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } img {max-width: 1200px;}',
                table_toolbar:
                  'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                table_appearance_options: false,
                table_advtab: false,
                table_cell_advtab: false,
                table_row_advtab: false,
                autosave_interval: '30s',
              }}
            />

            <TextField
              fullWidth
              multiline
              rows={8}
              id="brief"
              label="Brief du client"
              name="brief"
              autoComplete="brief"
              sx={{mt: 2}}
              value={inputs.brief || ''}
            />
            <TextField
              inputProps={{readOnly: true}}
              fullWidth
              multiline
              rows={5}
              id="comment"
              label="Commentaire manager"
              name="comment"
              autoComplete="comment"
              onChange={handleChange}
              sx={{mt: 2}}
              value={inputs.comment || ''}
            />
            <TextField
              fullWidth
              multiline
              rows={5}
              id="comment_corrector"
              label="Commentaire correcteur"
              name="comment_corrector"
              autoComplete="comment_corrector"
              onChange={handleChange}
              sx={{mt: 2}}
              value={inputs.comment_corrector || ''}
            />
            {inputs.motif_refus && (
              <TextField
                fullWidth
                multiline
                rows={5}
                id="motif_refus"
                label="Motif de refus"
                name="motif_refus"
                autoComplete="motif_refus"
                onChange={handleChange}
                sx={{mt: 2}}
                value={inputs.motif_refus || ''}
                inputProps={{readOnly: true}}
              />
            )}
          </Box>
        </Paper>
        <Box sx={{m: 1, position: 'relative'}}>
        <Tooltip title="Banques d'images">
            <Fab
              aria-label="images"
              color="success"
              onClick={() => {setOpenImagesApi(true)}}
              style={styleFab4}
            >
              <PhotoLibraryIcon />
            </Fab>
          </Tooltip>
          <ImagesApi idArticle={inputs.id_article} open={openImagesApi} handleClose={setOpenImagesApi} post_title={inputs.title} editorRef={editorRef} setImagePreview={setImagePreview} setInputs={setInputs} />
          
          <Tooltip title="Enregistrer">
            <Fab
              aria-label="save"
              color="warning"
              sx={buttonSx3}
              onClick={handleButtonClickSave}
              style={styleFab3}
            >
              {successSave ? <CheckIcon /> : <SaveIcon />}
            </Fab>
          </Tooltip>
          {loadingSave && (
            <CircularProgress
              style={{
                right: 154,
                bottom: 14,
                position: 'fixed',
              }}
              size={68}
              sx={{
                color: green[500],
                zIndex: 1,
              }}
            />
          )}
          <Tooltip title="Valider">
            <Fab
              aria-label="valid"
              color="primary"
              sx={buttonSx}
              onClick={handleButtonClick}
              style={styleFab2}
            >
              {success ? <CheckIcon /> : <AddTaskIcon />}
            </Fab>
          </Tooltip>
          {loading && (
            <CircularProgress
              style={{
                right: 84,
                bottom: 14,
                position: 'fixed',
              }}
              size={68}
              sx={{
                color: green[500],
                zIndex: 1,
              }}
            />
          )}
          <Tooltip title="Refuser">
            <Fab
              aria-label="to-correct"
              color="secondary"
              sx={buttonSx2}
              onClick={handleButtonClickCorrect}
              style={styleFab}
            >
              {successCorrect ? <CheckIcon /> : <BlockIcon />}
            </Fab>
          </Tooltip>
          {loadingCorrect && (
            <CircularProgress
              style={{
                right: 14,
                bottom: 14,
                position: 'fixed',
              }}
              size={68}
              sx={{
                color: green[500],
                zIndex: 1,
              }}
            />
          )}
        </Box>
      </Grid>
      <Snackbar
        open={openSuccess}
        autoHideDuration={4000}
        onClose={handleCloseSuccess}
        action={action}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{width: '100%'}}
        >
          {successMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default function CorrectArticle() {
  return <Layout children={<CorrectArticleContent />} />;
}
