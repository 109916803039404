import React from 'react'
import { Icon } from "@mui/material"
import WordpressLogo from '../assets/wp-icon.svg'

export default function CustomLogo(){
    return (
    <Icon sx={{width: 35, height: 35}}>
        <img src={WordpressLogo} />
    </Icon>
    )
}