import * as React from 'react';
import {useState} from 'react';
import axios from 'axios';
import Config from '../config.json';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {useOutletContext} from 'react-router-dom';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import CustomDonut from '../components/CustomDonut';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Title from '../components/Title';
import {DataGrid, GridToolbar, frFR} from '@mui/x-data-grid';
import CustomTitlePopOver from '../components/CustomTitlePopOver';
import CustomChip from '../components/CustomChip';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  TextField,
  Alert,
  Collapse,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import Papa from 'papaparse';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete } from '@mui/material';
import Layout from '../components/Layout';

ChartJS.register(ArcElement, Tooltip, Legend);

function DashboardContentAdmin(props) {
  const [date, setDate] = useState('month');
  const [update, setUpdate] = useState(Date.now());
  const [total, setTotal] = useState([]);
  const [articlesDashboard, setArticlesDashboard] = useState([]);
  const [users, setUsers] = useState([]);

  const handleChange = (event) => {
    setDate(event.target.value);
    setUpdate(Date.now());
  };

  React.useEffect(() => {
    const fetchStatsTotal = async () => {
      const totalFromApi = await axios.get(
        Config.BASE_URL + '/api/v2/total?date=' + date,
      );
      // console.log(totalFromApi.data);
      setTotal(totalFromApi.data);
    };

    const fetchArticlesDashboard = async () => {
      var admin = true;
      const articlesDashboardFromApi = await axios.get(
        Config.BASE_URL +
          '/api/v2/articles-dashboard?id=' +
          props.user.id_user +
          '&admin=' +
          admin,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      // console.log(articlesDashboardFromApi.data)
      setArticlesDashboard(articlesDashboardFromApi.data);
    };

    const fetchUsers = async () => {
      const usersFromApi = await axios.get(
        Config.BASE_URL + '/api/v2/users?stats=true',
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      setUsers(usersFromApi.data);
    };

    fetchArticlesDashboard();
    fetchStatsTotal();
    fetchUsers();
  }, [update]);

  return (
    <>
      <Grid item xs={12} md={8} lg={6}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 390,
          }}
        >
          <Typography variant="h6" color="#1976d2">
            Tous les articles
          </Typography>
          <Box
            sx={{
              minWidth: 120,
              display: 'flex',
              justifyContent: 'center',
              mb: 4,
            }}
          >
            <FormControl sx={{minWidth: 150}}>
              <InputLabel id="date-select-label">Période</InputLabel>
              <Select
                labelId="date-select-label"
                id="date-simple-select"
                value={date}
                label="Période"
                onChange={handleChange}
              >
                <MenuItem value={'month'}>Sur le mois</MenuItem>
                <MenuItem value={'year'}>Sur l'année</MenuItem>
                <MenuItem value={'all'}>Tous</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <CustomDonut total={total} />
        </Paper>
      </Grid>
      <Grid item xs={12} md={4} lg={6}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            minHeight: 390,
          }}
        >
          <Typography variant="h6" color="#1976d2">
            20 derniers articles
          </Typography>

          <div style={{width: '100%'}}>
            <DataGrid
              density="compact"
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              components={{
                Toolbar: GridToolbar,
              }}
              getRowId={(row) => row.id_article}
              columns={[
                {field: 'id_article', width: 80, headerName: 'Id'},
                {
                  field: 'title',
                  width: 480,
                  headerName: 'Titre',
                  renderCell: (params) => (
                    <CustomTitlePopOver content={params.value} />
                  ),
                },
                {
                  field: 'status',
                  width: 125,
                  headerName: 'Status',
                  renderCell: (params) => (
                    <CustomChip label={params.row.status} />
                  ),
                },
              ]}
              autoHeight
              rows={articlesDashboard}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
            />
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
          <Title>Statistiques par utilisateur</Title>
          <div style={{width: '100%'}}>
            <DataGrid
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              components={{
                Toolbar: GridToolbar,
              }}
              getRowId={(row) => row.id_user}
              columns={[
                {field: 'id_user', width: 80, headerName: 'Id'},
                {
                  field: 'name',
                  hideable: false,
                  width: 125,
                  headerName: 'Nom',
                },
                {field: 'firstname', width: 150, headerName: 'Prénom'},
                {field: 'role', width: 150, headerName: 'Rôle'},
                {
                  field: 'rediger_current',
                  width: 150,
                  headerName: 'À rédiger',
                  renderCell: (params) => {
                    var diff_rediger =
                      ((params.value - params.row.rediger_prev) /
                        params.row.rediger_prev) *
                      100;
                    diff_rediger = diff_rediger.toFixed(2);
                    if (diff_rediger == Infinity) {
                      return (
                        <span className="positif">
                          {params.value} / +{params.value}00 %
                        </span>
                      );
                    } else if (diff_rediger > 0) {
                      return (
                        <span className="positif">
                          {params.value} / +{diff_rediger} %
                        </span>
                      );
                    } else if (diff_rediger < 0) {
                      return (
                        <span className="negatif">
                          {params.value} / {diff_rediger} %
                        </span>
                      );
                    } else {
                      return <span>{params.value} / 0 %</span>;
                    }
                  },
                },
                {
                  field: 'redaction_current',
                  width: 150,
                  headerName: 'En rédaction',
                  renderCell: (params) => {
                    var diff_redaction =
                      ((params.value - params.row.redaction_prev) /
                        params.row.redaction_prev) *
                      100;
                    diff_redaction = diff_redaction.toFixed(2);

                    if (diff_redaction == Infinity) {
                      return (
                        <span className="positif">
                          {params.value} / +{params.value}00 %
                        </span>
                      );
                    } else if (diff_redaction > 0) {
                      return (
                        <span className="positif">
                          {params.value} / +{diff_redaction} %
                        </span>
                      );
                    } else if (diff_redaction < 0) {
                      return (
                        <span className="negatif">
                          {params.value} / {diff_redaction} %
                        </span>
                      );
                    } else {
                      return <span>{params.value} / 0 %</span>;
                    }
                  },
                },
                {
                  field: 'qualite_current',
                  width: 150,
                  headerName: 'En contrôle qualité',
                  renderCell: (params) => {
                    var diff_qualite =
                      ((params.value - params.row.qualite_prev) /
                        params.row.qualite_prev) *
                      100;
                    diff_qualite = diff_qualite.toFixed(2);

                    if (diff_qualite == Infinity) {
                      return (
                        <span className="positif">
                          {params.value} / +{params.value}00 %
                        </span>
                      );
                    } else if (diff_qualite > 0) {
                      return (
                        <span className="positif">
                          {params.value} / +{diff_qualite} %
                        </span>
                      );
                    } else if (diff_qualite < 0) {
                      return (
                        <span className="negatif">
                          {params.value} / {diff_qualite} %
                        </span>
                      );
                    } else {
                      return <span>{params.value} / 0 %</span>;
                    }
                  },
                },
                {
                  field: 'valide_current',
                  width: 150,
                  headerName: 'Validé',
                  renderCell: (params) => {
                    var diff_valide =
                      ((params.value - params.row.valide_prev) /
                        params.row.valide_prev) *
                      100;
                    diff_valide = diff_valide.toFixed(2);

                    if (diff_valide == Infinity) {
                      return (
                        <span className="positif">
                          {params.value} / +{params.value}00 %
                        </span>
                      );
                    } else if (diff_valide > 0) {
                      return (
                        <span className="positif">
                          {params.value} / +{diff_valide} %
                        </span>
                      );
                    } else if (diff_valide < 0) {
                      return (
                        <span className="negatif">
                          {params.value} / {diff_valide} %
                        </span>
                      );
                    } else {
                      return <span>{params.value} / 0 %</span>;
                    }
                  },
                },
                {
                  field: 'refuse_current',
                  width: 150,
                  headerName: 'Refusé',
                  renderCell: (params) => {
                    var diff_refuse =
                      ((params.value - params.row.refuse_prev) /
                        params.row.refuse_prev) *
                      100;
                    diff_refuse = diff_refuse.toFixed(2);

                    if (diff_refuse == Infinity) {
                      return (
                        <span className="positif">
                          {params.value} / +{params.value}00 %
                        </span>
                      );
                    } else if (diff_refuse > 0) {
                      return (
                        <span className="positif">
                          {params.value} / +{diff_refuse} %
                        </span>
                      );
                    } else if (diff_refuse < 0) {
                      return (
                        <span className="negatif">
                          {params.value} / {diff_refuse} %
                        </span>
                      );
                    } else {
                      return <span>{params.value} / 0 %</span>;
                    }
                  },
                },
                {
                  field: 'total_current',
                  width: 150,
                  headerName: 'Total',
                  renderCell: (params) => {
                    var diff_total =
                      ((params.value - params.row.total_prev) /
                        params.row.total_prev) *
                      100;
                    diff_total = diff_total.toFixed(2);

                    if (diff_total == Infinity) {
                      return (
                        <span className="positif">
                          {params.value} / +{params.value}00 %
                        </span>
                      );
                    } else if (diff_total > 0) {
                      return (
                        <span className="positif">
                          {params.value} / +{diff_total} %
                        </span>
                      );
                    } else if (diff_total < 0) {
                      return (
                        <span className="negatif">
                          {params.value} / {diff_total} %
                        </span>
                      );
                    } else {
                      return <span>{params.value} / 0 %</span>;
                    }
                  },
                },
              ]}
              autoHeight
              rows={users}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
            />
          </div>
        </Paper>
      </Grid>
    </>
  );
}

function DashboardContent(props) {
  const [totalUser, setTotalUser] = useState([]);
  const [date, setDate] = useState('month');
  const [update, setUpdate] = useState(Date.now());
  const [compare, setCompare] = useState([]);
  const [tags, setTags] = useState([]);

  React.useEffect(() => {
    var role = 'redac';
    if (props.user.id_role == 4) {
      role = 'correc';
    }
    const fetchStatsTotalUser = async () => {
      const totalUserFromApi = await axios.get(
        Config.BASE_URL +
          '/api/v2/total-user?date=' +
          date +
          '&id=' +
          props.user.id_user +
          '&role=' +
          role,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      // console.log(totalUserFromApi.data)
      setTotalUser(totalUserFromApi.data);
    };

    const fetchCompare = async () => {
      const compareFromApi = await axios.get(
        Config.BASE_URL +
          '/api/v2/compare?id=' +
          props.user.id_user +
          '&role=' +
          role,
      );
      setCompare(compareFromApi.data);
    };

    fetchCompare();
    fetchStatsTotalUser();
  }, [update]);

  const handleChange = (event) => {
    setDate(event.target.value);
    setUpdate(Date.now());
  };

  return (
    <>
      <Grid item xs={12} md={8} lg={6}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 390,
          }}
        >
          <Typography variant="h6" color="#1976d2">
            Tous les articles
          </Typography>
          <Box
            sx={{
              minWidth: 120,
              display: 'flex',
              justifyContent: 'center',
              mb: 4,
            }}
          >
            <FormControl sx={{minWidth: 150}}>
              <InputLabel id="date-select-label">Période</InputLabel>
              <Select
                labelId="date-select-label"
                id="date-simple-select"
                value={date}
                label="Période"
                onChange={handleChange}
              >
                <MenuItem value={'month'}>Sur le mois</MenuItem>
                <MenuItem value={'year'}>Sur l'année</MenuItem>
                <MenuItem value={'all'}>Tous</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <CustomDonut total={totalUser} />
        </Paper>
      </Grid>
      <Grid item xs={12} md={4} lg={6}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            minHeight: 390,
          }}
        >
          <Typography variant="h6" color="#1976d2">
            Comparatif :
          </Typography>
          {compare.is_positive == 1 && (
            <div className="align">
              <Typography>
                Ce mois : {compare.current_month} articles -{' '}
              </Typography>{' '}
              <Typography className="positif">
                +
                {compare.percent_difference == null &&
                  compare.current_month + '00'}{' '}
                {compare.percent_difference !== null &&
                  compare.percent_difference}
                %
              </Typography>
            </div>
          )}
          {compare.is_positive == 0 && (
            <div className="align">
              <Typography>
                Ce mois : {compare.current_month} articles -
              </Typography>{' '}
              <Typography className="negatif">
                {compare.percent_difference} %
              </Typography>
            </div>
          )}
          <Typography>Mois passé : {compare.last_month} articles</Typography>

          <Typography variant="h6" color="#1976d2">
            Statut des articles :
          </Typography>
          <Typography>À rédiger : {compare.rediger}</Typography>
          <Typography>En rédaction : {compare.redaction}</Typography>
          <Typography>En contrôle qualité : {compare.qualite}</Typography>
          <Typography>Validé : {compare.valide}</Typography>
          <Typography>Refusé : {compare.refuse}</Typography>
        </Paper>
      </Grid>
    </>
  );
}

function DashboardContentGC(props) {
  const [totalUser, setTotalUser] = useState([]);
  const [date, setDate] = useState('month');
  const [update, setUpdate] = useState(Date.now());
  const [compareGc, setCompareGc] = useState([]);
  const [tags, setTags] = useState([]);

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  React.useEffect(() => {
    var role = 'gc';
    const fetchStatsTotalUser = async () => {
      const totalUserFromApi = await axios.get(
        Config.BASE_URL +
          '/api/v2/total-user?date=' +
          date +
          '&id=' +
          props.user.id_user +
          '&role=' +
          role,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      setTotalUser(totalUserFromApi.data);
    };

    const fetchCompareGc = async () => {
      const compareGcFromApi = await axios.get(
        Config.BASE_URL +
          '/api/v2/compare?id=' +
          props.user.id_user +
          '&role=' +
          role,
      );
      // console.log(compareGcFromApi.data);
      setCompareGc(compareGcFromApi.data);
    };

    const loadTags = async () => {
      const tagsFromApi = await axios.get(Config.BASE_URL + '/api/v2/tags', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      // console.log(tagsFromApi.data)
      var arr = Array.from(tagsFromApi.data);
      var nametag = [];
      arr.forEach((el) => {
        nametag.push({label: el.tag, id: el.id_tag});
      });
      setTags(nametag);
    };

    loadTags();
    fetchCompareGc();
    fetchStatsTotalUser();
  }, [update]);

  const handleChange = (e) => {
    setDate(e.target.value);
    setUpdate(Date.now());
    setInputs((values) => ({...values, [e.target.name]: e.target.value}));
  };

  var [inputs, setInputs] = useState({['nl']: 'false', ['language']: 'false'});
  const [isLoading, setIsLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [openSuccess, setOpenSuccess] = useState(false);

  const [language, setLanguage] = useState(false);
  const handleLanguage = (e) => {
    setLanguage(e.target.value);
    setInputs((values) => ({...values, [e.target.name]: e.target.value}));
  };

  const [nl, setNl] = useState(false);
  const handleNl = (e) => {
    setNl(e.target.value);
    setInputs((values) => ({...values, [e.target.name]: e.target.value}));
  };

  const [qualite, setQualite] = useState(2);
  const handleQualite = (e) => {
    setQualite(e.target.value);
    setInputs((values) => ({...values, [e.target.name]: e.target.value}));
  };

  const handleInputs = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({...values, [name]: value}));
  };

  const [count, setCount] = useState('');

  const handleSubmitList = (e) => {
    e.preventDefault();
    // console.log(inputs)
    setIsLoading(true);
    setTimeout(() => {
      axios
        .post(
          Config.BASE_URL + '/api/v2/article/addList?role=gc',
          {inputs: inputs, user: props.user},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        )
        .then((response) => {
          //console.log(response.data)
          if (response.data.success == 0) {
            setError(response.data.message);
            setIsError(true);
            setOpenError(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setInputs({['articles_list']: '', ['brief']: ''});
            setOpenConfirm(true);
            setCount(response.data.count);
            setUpdate(Date.now());
          }
        });
    }, 1000);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };

  const onDownload = () => {
    const link = document.createElement('a');
    link.download = `import-articles-gc.csv`;
    link.href = Config.BASE_URL + '/import-articles-gc.csv';
    link.click();
  };

  const [parsedData, setParsedData] = useState([]);

  const handleImport = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      comments: '//',
      transformHeader: function (h) {
        return h.toLowerCase();
      },
      complete: function (results) {
        setParsedData(results.data);
      },
    });
  };

  const [openError, setOpenError] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  var display = 'none';
  if (isError) {
    display = 'flex';
  }

  const handleSubmitImport = (event) => {
    event.preventDefault();
    // console.log({parsedData : parsedData, inputs: inputs})
    setIsLoading(true);
    setTimeout(() => {
      axios
        .post(
          Config.BASE_URL + '/api/v2/article/import?role=gc',
          {parsedData: parsedData, inputs: inputs, user: props.user},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        )
        .then((response) => {
          // console.log(response.data)
          if (response.data.success == 0) {
            setError(response.data.message);
            setIsError(true);
            setOpenError(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setParsedData([]);
            setOpenConfirm(true);
            setCount(response.data.count);
            setUpdate(Date.now());
          }
        });
    }, 1000);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSuccess}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [tag, setTag] = useState('');
  const handleTag = (e) => {
    setTag(e.target.value);
    setInputs((values) => ({...values, [e.target.name]: e.target.value}));
  };

  return (
    <>
      <Grid item xs={12} md={8} lg={6}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 390,
          }}
        >
          <Typography variant="h6" color="#1976d2">
            Tous les articles
          </Typography>
          <Box
            sx={{
              minWidth: 120,
              display: 'flex',
              justifyContent: 'center',
              mb: 4,
            }}
          >
            <FormControl sx={{minWidth: 150}}>
              <InputLabel id="date-select-label">Période</InputLabel>
              <Select
                labelId="date-select-label"
                id="date-simple-select"
                value={date}
                label="Période"
                onChange={handleChange}
              >
                <MenuItem value={'month'}>Sur le mois</MenuItem>
                <MenuItem value={'year'}>Sur l'année</MenuItem>
                <MenuItem value={'all'}>Tous</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <CustomDonut total={totalUser} />
        </Paper>
      </Grid>
      <Grid item xs={12} md={4} lg={6}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            minHeight: 390,
          }}
        >
          <Typography variant="h6" color="#1976d2">
            Comparatif :
          </Typography>
          {compareGc.is_positive == 1 && (
            <div className="align">
              <Typography>
                Ce mois : {compareGc.current_month} articles -{' '}
              </Typography>{' '}
              <Typography className="positif">
                +
                {compareGc.percent_difference == null &&
                  compareGc.current_month + '00'}{' '}
                {compareGc.percent_difference !== null &&
                  compareGc.percent_difference}
                %
              </Typography>
            </div>
          )}
          {compareGc.is_positive == 0 && (
            <div className="align">
              <Typography>
                Ce mois : {compareGc.current_month} articles -
              </Typography>{' '}
              <Typography className="negatif">
                {compareGc.percent_difference} %
              </Typography>
            </div>
          )}
          <Typography>Mois passé : {compareGc.last_month} articles</Typography>

          <Typography variant="h6" color="#1976d2">
            Statut des articles :
          </Typography>
          {compareGc.rediger == null ? (
            <Typography>À rédiger : 0</Typography>
          ) : (
            <Typography>À rédiger : {compareGc.rediger}</Typography>
          )}
          {compareGc.redaction == null ? (
            <Typography>En rédaction : 0</Typography>
          ) : (
            <Typography>En rédaction : {compareGc.redaction}</Typography>
          )}
          {compareGc.qualite == null ? (
            <Typography>En contrôle qualité : 0</Typography>
          ) : (
            <Typography>En contrôle qualité : {compareGc.qualite}</Typography>
          )}
          {compareGc.valide == null ? (
            <Typography>Validé : 0</Typography>
          ) : (
            <Typography>Validé : {compareGc.valide}</Typography>
          )}
          {compareGc.refuse == null ? (
            <Typography>Refusé : 0</Typography>
          ) : (
            <Typography>Refusé : {compareGc.refuse}</Typography>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
          <Stack direction="row">
            <InfoIcon sx={{mr: 2}} /> Chaque import de CSV ou de liste créera
            automatiquement un lot contenant les articles.{' '}
          </Stack>
          <Accordion sx={{mb: 2, mt: 2}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Importer CSV</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Collapse in={openError}>
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{display: display, mb: 2}}
                >
                  {error}
                </Alert>
              </Collapse>
              <Typography>
                Fichier source :{' '}
                <span className="download-link" onClick={onDownload}>
                  Télécharger
                </span>
              </Typography>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                sx={{mt: 2}}
              >
                <TextField
                  fullWidth
                  id="site"
                  label="Site"
                  name="site"
                  autoComplete="site"
                  onChange={handleChange}
                  placeholder="https://votre-site.com"
                  value={inputs.site || ''}
                  helperText={
                    inputs.site &&
                    "Attention, cette option ne permet pas l'import direct"
                  }
                />

                <FormControl fullWidth>
                  <InputLabel id="language-select-label">
                    Anglais ? *
                  </InputLabel>
                  <Select
                    labelId="language-select-label"
                    required
                    id="language"
                    label="Anglais ? *"
                    name="language"
                    onChange={handleLanguage}
                    value={language}
                  >
                    <MenuItem value={'true'}>Oui</MenuItem>
                    <MenuItem value={'false'}>Non</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="nl-select-label">Nextlevel ? *</InputLabel>
                  <Select
                    labelId="nl-select-label"
                    required
                    id="nl"
                    label="Nextlevel ? *"
                    name="nl"
                    onChange={handleNl}
                    value={nl}
                  >
                    <MenuItem value={'true'}>Oui</MenuItem>
                    <MenuItem value={'false'}>Non</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="qualite-select-label">Qualité ? *</InputLabel>
                  <Select
                    labelId="qualite-select-label"
                    required
                    id="qualite"
                    label="Qualité ? *"
                    name="qualite"
                    onChange={handleQualite}
                    value={qualite}
                  >
                    <MenuItem value={3}>Premium</MenuItem>
                    <MenuItem value={2}>Classic</MenuItem>
                  </Select>
                </FormControl>

                <Autocomplete
                  name="id_tag"
                  fullWidth
                  renderOption={(props, option) => {
                    // console.log(option)
                    return (
                      <li {...props} key={option.id}>
                        {option.label}
                      </li>
                    );
                  }}
                  onChange={(e, newValue) => {
                    //console.log(newValue)
                    setInputs((values) => ({
                      ...values,
                      ['id_tag']: newValue,
                    }));
                  }}
                  value={inputs.id_tag || ''}
                  id="id_tag"
                  options={tags}
                  renderInput={(params) => (
                    <TextField {...params} label="Thématique" />
                  )}
                />
              </Stack>

              <Box
                component="form"
                onSubmit={handleSubmitImport}
                noValidate
                sx={{mt: 1}}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="file"
                  type="file"
                  id="file"
                  inputProps={{accept: '.csv'}}
                  onChange={handleImport}
                />
                <LoadingButton
                  type="submit"
                  fullWidth
                  loading={isLoading}
                  variant="contained"
                  sx={{mt: 2}}
                >
                  Importer
                </LoadingButton>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{mb: 2}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Commander liste d'articles</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Collapse in={openError}>
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{display: display, mb: 2}}
                >
                  {error}
                </Alert>
              </Collapse>
              <Typography>⚠️ Un titre par ligne</Typography>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                sx={{mt: 2}}
              >
                <TextField
                  fullWidth
                  id="site"
                  label="Site"
                  name="site"
                  autoComplete="site"
                  onChange={handleChange}
                  placeholder="https://votre-site.com"
                  value={inputs.site || ''}
                  helperText={
                    inputs.site &&
                    "Attention, cette option ne permet pas l'import direct"
                  }
                />

                <FormControl fullWidth>
                  <InputLabel id="language-select-label">
                    Anglais ? *
                  </InputLabel>
                  <Select
                    labelId="language-select-label"
                    required
                    id="language"
                    label="Anglais ? *"
                    name="language"
                    onChange={handleLanguage}
                    value={language}
                  >
                    <MenuItem value={'true'}>Oui</MenuItem>
                    <MenuItem value={'false'}>Non</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="nl-select-label">Nextlevel ? *</InputLabel>
                  <Select
                    labelId="nl-select-label"
                    required
                    id="nl"
                    label="Nextlevel ? *"
                    name="nl"
                    onChange={handleNl}
                    value={nl}
                  >
                    <MenuItem value={'true'}>Oui</MenuItem>
                    <MenuItem value={'false'}>Non</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="qualite-select-label">Qualité ? *</InputLabel>
                  <Select
                    labelId="qualite-select-label"
                    required
                    id="qualite"
                    label="Qualité ? *"
                    name="qualite"
                    onChange={handleQualite}
                    value={qualite}
                  >
                    <MenuItem value={3}>Premium</MenuItem>
                    <MenuItem value={2}>Classic</MenuItem>
                  </Select>
                </FormControl>

                <Autocomplete
                  name="id_tag"
                  fullWidth
                  renderOption={(props, option) => {
                    // console.log(option)
                    return (
                      <li {...props} key={option.id}>
                        {option.label}
                      </li>
                    );
                  }}
                  onChange={(e, newValue) => {
                    //console.log(newValue)
                    setInputs((values) => ({
                      ...values,
                      ['id_tag']: newValue,
                    }));
                  }}
                  value={inputs.id_tag || ''}
                  id="id_tag"
                  options={tags}
                  renderInput={(params) => (
                    <TextField {...params} label="Thématique" />
                  )}
                />
              </Stack>

              <Box
                component="form"
                onSubmit={handleSubmitList}
                noValidate
                sx={{mt: 1}}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  id="articles_list"
                  label="Liste"
                  name="articles_list"
                  autoComplete="articles_list"
                  placeholder="ex : Comment obtenir… en X temps ?"
                  multiline
                  rows={10}
                  onChange={handleInputs}
                  value={inputs.articles_list}
                />

                <TextField
                  margin="normal"
                  fullWidth
                  id="brief"
                  label="Brief"
                  name="brief"
                  autoComplete="brief"
                  placeholder="Brief de mon lot"
                  multiline
                  rows={10}
                  onChange={handleInputs}
                  value={inputs.brief}
                />

                <LoadingButton
                  type="submit"
                  fullWidth
                  loading={isLoading}
                  variant="contained"
                  sx={{mt: 2}}
                  onChange={handleChange}
                >
                  Commander la liste
                </LoadingButton>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Paper>
        <Dialog
          open={openConfirm}
          onClose={handleCloseConfirm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Commande validée'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Votre commande de {count} articles est validée !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirm}>Merci</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
}

export default function Dashboard() {
  const loggedUser = useOutletContext();
  // console.log(loggedUser[0]);

  if (loggedUser[0].id_role == 1 || loggedUser[0].id_role == 2) {
    return <Layout children={<DashboardContentAdmin user={loggedUser[0]} />}/>;
  } else if (loggedUser[0].id_role == 3 || loggedUser[0].id_role == 4) {
    return <Layout children={<DashboardContent user={loggedUser[0]} />}/>;
  } else if (loggedUser[0].id_role == 5) {
    return <Layout children={<DashboardContentGC user={loggedUser[0]} />}/>;
  }
}
