import React, { useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
const CustomDonut = (props) =>{
    const data = props.total
  
  const dataLabels = data.map(({orderType})=>orderType);
  const dataInput = data.map(({value})=>value);
  const datasets = {
    labels: dataLabels,
    datasets:[
      {
        label: 'Nombre d\'articles',
        data: dataInput,
        backgroundColor: [
          'rgb( 60, 3, 93)',
          'rgb(	252	,162,	0)',
          'rgb(214	,0,	136)',
          'rgb(143,	206, 0)',
          'rgb(	244, 67, 54)',
          'rgb(	143, 255, 54)',
          'rgb(	255, 50, 54)',

        ],
        hoverOffset: 10,
      }
    ]
  };
  const options = {
    maintainAspectRatio: false, 
    responsive: true,
    plugins:{
      scaleShowLabels:false,
      legend: {
        display: true,
        position: 'left'
      }
    },
    layout: { padding: { left: 50, right: 150} },
  };
  return (
    <div style={{width: "750px", height: "300px"}}>
      <Doughnut type="doughnut"
        data={datasets}
        options={options}
      />      
    </div>

  )
}
export default CustomDonut