import 'devextreme/dist/css/dx.light.css';
import React from "react";
import "./App.css";
import Router from "./components/Router";

function App() {
  

  return (
    <div className="App">
      
      <Router/>
    </div>
  );
}

export default App;
