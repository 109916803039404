import React, { useState } from 'react';
import { ImageList, ImageListItem, Button, Card, CardMedia, Box, Stack, Typography } from '@mui/material';
import axios from 'axios';
import Config from '../config.json';

export default function Unsplash({ images, editorRef, handleClose, setSearch, setImagePreview, setImages, setInputs, setOpenLoader, idArticle }) {
    const [hovered, setHovered] = useState(null);

    const handleMouseEnter = (id) => {
        setHovered(id);
    };

    const handleMouseLeave = () => {
        setHovered(null);
    };

    const handleInsert = (imgSrc, id) => {
        setOpenLoader(true)


        axios.post(Config.BASE_URL + '/api/v2/images/unsplash', {
            imgSrc,
            id,
            idArticle
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        })
        .then((response) => {
            editorRef.current.insertContent(`<img src="${response.data}" />`);
            handleClose(false)
            setSearch('')
            setOpenLoader(false)
            setImages([])
        })
        .catch((error) => {
        })
    }

    const handleThumbnail = (imgSrc, id) => {
        setOpenLoader(true)

        axios.post(Config.BASE_URL + '/api/v2/images/unsplash', {
            id: id,
            mode: 'thumbnail',
            idArticle : idArticle
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        })
        .then((response) => {
            setImagePreview(response.data)
            setInputs((values) => ({
                ...values,
                ['thumbnail_upload']: {
                  data: response.data,
                  name: id + '.jpeg',
                },
              }));
            handleClose(false)
            setSearch('')
            setOpenLoader(false)
            setImages([])
        })
        .catch((error) => {
        })
    }

    return (
        <>
            <ImageList variant="masonry" cols={3} gap={8}>
    {images.map((image) => (
        <ImageListItem key={image.id}>
            <Card 
                sx={{
                    position: 'relative',
                    width: '100%',  
                }}
                onMouseEnter={() => handleMouseEnter(image.id)} 
                onMouseLeave={handleMouseLeave}
            >
                <CardMedia
                    component="img"
                    sx={{
                        filter: hovered === image.id ? 'blur(2px) brightness(0.7)' : 'none', 
                    }}
                    image={image.urls.small}
                    title={image.alt_description}
                />
                {hovered === image.id && (
                    <React.Fragment>
                        <Box 
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        >
                            <Stack spacing={1}>
                                <Button sx={{ fontSize: '11px'}} size='small' variant="contained" color="primary" onClick={() => handleInsert(image.urls.full, image.id)}>
                                    Insérer dans le contenu
                                </Button>
                                <Button sx={{ fontSize: '11px'}} size='small' variant="contained" color="secondary" onClick={() => handleThumbnail(image.links.download_location, image.id)}>
                                    Choisir comme thumbnail
                                </Button>
                            </Stack>
                        </Box>

                        <Box 
                            sx={{
                                position: 'absolute',
                                bottom: '10px',
                                left: '10px',
                                backgroundColor: 'rgba(0,0,0,0.6)',
                                color: '#fff',
                                borderRadius: '5px',
                                padding: '2px 5px',
                            }}
                        >
                            <Typography variant="subtitle2">
                                <a href={image.user.links.html} style={{color: '#fff'}} target="_blank" rel="noreferrer">
                                    {image.user.name}
                                </a>
                            </Typography>
                        </Box>
                    </React.Fragment>
                )}
            </Card>
        </ImageListItem>
    ))}
</ImageList>

        </>
    );
}
