import * as React from 'react';
import { Box } from '@mui/system';
import { DialogTitle, Dialog, ListItemText, ListItem, List } from '@mui/material';

export default function Checklist(props) {

   

  return (
    <Dialog onClose={props.onClose} open={props.open}>
    <Box sx={{p: 6}}>
      <DialogTitle>Checklist d'un 'bon' article</DialogTitle>
      <List>
        <ListItem>
          <ListItemText primary="1- Pas de faute." />
        </ListItem>
        <ListItem>
          <ListItemText primary="2- Pas de remplissage." />
        </ListItem>
        <ListItem>
          <ListItemText primary="3- Une intro winner." />
        </ListItem>
        <ListItem>
          <ListItemText primary="4- Des titres percutants." />
        </ListItem>
        <ListItem>
          <ListItemText primary="5- Au moins une liste à puce (courte et n'ayant pas le même premier mot)." />
        </ListItem>
        <ListItem>
          <ListItemText primary="6- Des images qui correspondent au contexte." />
        </ListItem>
        <ListItem>
          <ListItemText primary="7- Un champ lexical approprié en fonction du titre H1." />
        </ListItem>
        <ListItem>
          <ListItemText primary="8- Pas de tournures étranges." />
        </ListItem>
        <ListItem>
          <ListItemText primary="9- Des phrases courtes." />
        </ListItem>
        <ListItem>
          <ListItemText primary="10- Un texte aéré." />
        </ListItem>
        <ListItem>
          <ListItemText primary="11- Chaque phrase doit offrir de la valeur au lecteur." />
        </ListItem>
        <ListItem>
          <ListItemText primary="12- Une ponctuation appropriée." />
        </ListItem>
        <ListItem>
          <ListItemText primary="13- Pas de phrases lourdes." />
        </ListItem>
        <ListItem>
          <ListItemText primary="14- Prendre en compte le lecteur plutôt que son propre style et champ lexical." />
        </ListItem>
      </List>
    </Box>
  </Dialog>
    );
}